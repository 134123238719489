import * as Api from "api";
import {
  CREATE_GIT_REPO,
  DELETE_GIT_REPO,
  FORCE_UPDATE_GIT_REPO,
  GET_GIT_REPOS,
  GET_GIT_REPO_BY_ID,
  PROTECT_GIT_REPO,
  STAR_GIT_REPO,
  UPDATE_GIT_REPO,
} from "constants/gitRepos";
import { networkAction } from "helpers/network/networkAction";

export const getGitRepos = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_GIT_REPOS, Api.getGitRepos, [filters]);

export const getGitRepoById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GIT_REPO_BY_ID, Api.getGitRepoById, [id]);

export const deleteGitRepo = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_GIT_REPO, Api.deleteGitRepo, [id]);

export const createGitRepo = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GIT_REPO, Api.createGitRepo, [body]);

export const updateGitRepo = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GIT_REPO, Api.updateGitRepo, [id, body]);

export const starGitRepo = (id) => async (dispatch) =>
  networkAction(dispatch, STAR_GIT_REPO, Api.starGitRepo, [id]);

export const protectGitRepo = (id) => async (dispatch) =>
  networkAction(dispatch, PROTECT_GIT_REPO, Api.protectGitRepo, [id]);

export const forceUpdateGitRepo = (id) => async (dispatch) =>
  networkAction(dispatch, FORCE_UPDATE_GIT_REPO, Api.forceUpdateGitRepo, [id]);

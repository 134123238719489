// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperGitReposPage from "../component/WrapperGitReposPage";

const mapStateToProps = (state) => ({
  gitRepos: state.gitRepos,
  shipNodes: state.gitRepos,
  shipStages: state.shipStages,
  shipServices: state.shipServices,
});

const mapDispatchToProps = (dispatch) => ({
  getGitRepos: (filters) => dispatch(Actions.getGitRepos(filters)),
  getShipNodes: () => dispatch(Actions.getShipNodes()),
  getShipStages: () => dispatch(Actions.getShipStages()),
  getShipServices: () => dispatch(Actions.getShipServices()),
  getGitRepoById: (...args) => dispatch(Actions.getGitRepoById(...args)),
  createGitRepo: (...args) => dispatch(Actions.createGitRepo(...args)),
  deleteGitRepo: (...args) => dispatch(Actions.deleteGitRepo(...args)),
  updateGitRepo: (...args) => dispatch(Actions.updateGitRepo(...args)),
  starGitRepo: (...args) => dispatch(Actions.starGitRepo(...args)),
  protectGitRepo: (...args) => dispatch(Actions.protectGitRepo(...args)),
  forceUpdateGitRepo: (...args) =>
    dispatch(Actions.forceUpdateGitRepo(...args)),
});
class GitReposPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    gitRepos: PropTypes.array,
    getGitRepos: PropTypes.func,
    getGitRepoById: PropTypes.func,
    createGitRepo: PropTypes.func,
    deleteGitRepo: PropTypes.func,
    updateGitRepo: PropTypes.func,

    starGitRepo: PropTypes.func,
    protectGitRepo: PropTypes.func,
    forceUpdateGitRepo: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, gitRepos } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: gitRepos.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const {
      getGitRepos,
      getShipServices,
      getShipStages,
      getShipNodes,
    } = this.props;
    await Promise.all([
      getGitRepos(),
      getShipServices(),
      getShipStages(),
      getShipNodes(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      gitRepos,
      getGitRepoById,
      createGitRepo,
      deleteGitRepo,
      updateGitRepo,
      starGitRepo,
      protectGitRepo,
      forceUpdateGitRepo,
      getGitRepos,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperGitReposPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        gitRepos={gitRepos}
        getGitRepoById={getGitRepoById}
        createGitRepo={createGitRepo}
        deleteGitRepo={deleteGitRepo}
        updateGitRepo={updateGitRepo}
        starGitRepo={starGitRepo}
        protectGitRepo={protectGitRepo}
        forceUpdateGitRepo={forceUpdateGitRepo}
        getGitRepos={getGitRepos}
        history={history}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GitReposPage);

import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "arranger";
const projects = `${REACT_APP_API_PREFIX}/${service}/projects`;

export function getProjects() {
  const url = `${projects}`;
  return {
    method: GET,
    url,
  };
}

export function getProjectById(id) {
  const url = `${projects}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProject(id, body) {
  const url = `${projects}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProject(id) {
  const url = `${projects}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProject(body) {
  const url = `${projects}`;
  return {
    method: POST,
    url,
    body,
  };
}

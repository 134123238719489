import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions
import * as Actions from "actions";

// component
import WrapperSigninPage from "../component/WrapperSigninPage";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  signin: (...args) => dispatch(Actions.signin(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
});
class SigninPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // api
    signin: PropTypes.func,
    getCurrentUser: PropTypes.func,

    // reducer
    session: PropTypes.object,
    app: PropTypes.object,
  };

  render() {
    const {
      history,
      location,
      signin,
      session,
      getCurrentUser,
      app,
    } = this.props;
    return (
      <WrapperSigninPage
        history={history}
        location={location}
        getCurrentUser={getCurrentUser}
        signin={signin}
        session={session}
        app={app}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);

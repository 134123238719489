import { GET_SHIP_SERVICES } from "constants/shipServices";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SHIP_SERVICES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};

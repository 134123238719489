export default (theme) => ({
  background: {
    background: theme.palette.background.default,
    paddingTop: 30,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  header: {
    background: theme.palette.background.paper,
    paddingLeft: 10,
  },
  tabsIndicator: {
    color: "#000000",
  },
});

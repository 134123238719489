import React, { Component } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import blue from "@material-ui/core/colors/blue";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import Code from "@material-ui/icons/Code";
import BugReport from "@material-ui/icons/BugReport";
import Chat from "@material-ui/icons/Chat";

// custom
import SupportItem from "components/SupportItem";

// helpers
import {} from "helpers";

// styles
import styles from "./styles";

class WrapperSupportPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container>
            <Grid xs={12}>
              <Typography display="block" variant="h4" color="secondary">
                November 2018
              </Typography>
              <Typography display="block" variant="h6" color="textSecondary">
                Support Support
              </Typography>
            </Grid>
            <Grid xs={12} md={3}>
              <SupportItem
                unit="Hours"
                rate={90}
                extraRate={150}
                editable
                recommendedValue={15}
                minValue={5}
                currentValue={8.33}
                maxValue={30}
                setValue={20}
                setDate={""}
                supportItemType={{
                  name: "Active Support",
                  icon: Code,
                  description:
                    "Time related to the pipeline's new feature development.",
                  tagID: 1,
                  color: blue,
                }}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <SupportItem
                unit="Hours"
                rate={90}
                extraRate={150}
                editable
                recommendedValue={15}
                minValue={5}
                currentValue={8.33}
                maxValue={30}
                setValue={20}
                setDate={""}
                supportItemType={{
                  name: "Proactive Support",
                  icon: BugReport,
                  description:
                    "Time related to code base and database update and managment.",
                  tagID: 1,
                  color: orange,
                }}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <SupportItem
                unit="Hours"
                rate={90}
                extraRate={150}
                editable
                recommendedValue={15}
                minValue={5}
                currentValue={8.33}
                maxValue={30}
                setValue={20}
                setDate={""}
                supportItemType={{
                  name: "Pro Support",
                  icon: Chat,
                  description:
                    "Time accorded to meeting, slack, phone call, email...",
                  tagID: 3,
                  color: lightGreen,
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Typography display="block" variant="title">
            Item Two
          </Typography>
        );
      default:
        return (
          <Typography display="block" variant="h4">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history } = this.props;

    const { index } = this.state;

    return (
      <div className="page">
        <Helmet>
          <title>Support</title>
          <meta name="Support" content="Support page" />
        </Helmet>
        <div className="root">
          <AppBar position="absolute" color="default">
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              <Tab
                label="DASHBOARD"
                classes={{
                  root: "tabRoot",
                  selected: "tabSelected",
                }}
              />
              <Tab
                label="LOG REPORTS"
                classes={{
                  root: "tabRoot",
                  selected: "tabSelected",
                }}
              />
            </Tabs>
          </AppBar>
          <div className={`container ${classes.background} withAppBarAndTabs`}>
            {this.getStepContent()}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperSupportPage);

import { networkAction } from "helpers/network/networkAction";

import {
  GET_SUPPORT_ITEMS,
  GET_SUPPORT_ITEM_BY_ID,
  DELETE_SUPPORT_ITEM,
  UPDATE_SUPPORT_ITEM,
  CREATE_SUPPORT_ITEM,
} from "constants/supportItems";

import * as Api from "api";

export const getSupportItems = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_SUPPORT_ITEMS, Api.getSupportItems, [filters]);

export const getSupportItemById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SUPPORT_ITEM_BY_ID, Api.getSupportItemById, [id]);

export const deleteSupportItem = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUPPORT_ITEM, Api.deleteSupportItem, [id]);

export const createSupportItem = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUPPORT_ITEM, Api.createSupportItem, [body]);

export const updateSupportItem = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUPPORT_ITEM, Api.updateSupportItem, [
    id,
    body,
  ]);

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperUsersPage from "../component/WrapperUsersPage";

const mapStateToProps = (state) => ({
  users: state.users,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(Actions.getUsers()),
  getUserById: (...args) => dispatch(Actions.getUserById(...args)),
  createUser: (...args) => dispatch(Actions.createUser(...args)),
  deleteUser: (...args) => dispatch(Actions.deleteUser(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),

  getGroups: (...args) => dispatch(Actions.getGroups(...args)),
  getUserGroups: (...args) => dispatch(Actions.getUserGroups(...args)),
  createUserGroup: (...args) => dispatch(Actions.createUserGroup(...args)),
  deleteUserGroup: (...args) => dispatch(Actions.deleteUserGroup(...args)),
});
class UsersPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    users: PropTypes.array,
    getUsers: PropTypes.func,
    getUserById: PropTypes.func,
    createUser: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,

    groups: PropTypes.array,
    getGroups: PropTypes.func,
    getUserGroups: PropTypes.func,
    createUserGroup: PropTypes.func,
    deleteUserGroup: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, users } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: users.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getUsers, getGroups } = this.props;
    await Promise.all([getUsers(), getGroups()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      users,
      getUserById,
      createUser,
      deleteUser,
      updateUser,
      groups,
      getUserGroups,
      createUserGroup,
      deleteUserGroup,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperUsersPage
        refresh={this.refresh.bind(this)}
        history={history}
        loading={loading}
        users={users}
        getUserById={getUserById}
        createUser={createUser}
        deleteUser={deleteUser}
        updateUser={updateUser}
        urlParams={urlParams}
        groups={groups}
        getUserGroups={getUserGroups}
        createUserGroup={createUserGroup}
        deleteUserGroup={deleteUserGroup}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// material-ui
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Support from "components/Support";
// routes
import { CUSTOMERS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Page from "../../../components/Page";
// styles
import styles from "./styles";

class WrapperCustomerPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    app: PropTypes.object,
    utils: PropTypes.object,
    user: PropTypes.object,
    timelogs: PropTypes.array,
    supportItemTypes: PropTypes.array,
    users: PropTypes.array,
    loading: PropTypes.bool,
    customer: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    urlParams: PropTypes.object,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const {
      classes,
      customer,
      history,
      app,
      loading,
      timelogs,
      user,
      supportItemTypes,
      utils,
    } = this.props;

    return (
      <Page
        helmet="Customer"
        loading={!customer}
        header={
          <Grid
            container
            className={classes.header}
            alignItems="center"
            spacing={2}
            style={{
              marginTop: 0,
            }}
          >
            <Grid item>
              <Chip
                icon={<ArrowBack />}
                label="Back"
                onClick={() => history.push(CUSTOMERS)}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Avatar src={customer?.logo} />
            </Grid>
            <Typography display="block" variant="h6">
              {customer?.name}
            </Typography>
          </Grid>
        }
      >
        <Support
          utils={utils}
          supportItemTypes={supportItemTypes}
          customer={customer}
          user={user}
          timelogs={timelogs}
          app={app}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCustomerPage);

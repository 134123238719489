import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";

// styles
import styles from "./styles";

class Timelog extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timelog: PropTypes.object,
    utils: PropTypes.object,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { timelog } = this.props;
    let interval;
    if (!timelog.endDate) {
      interval = setInterval(this.setDuration.bind(this), 1000);
    }
    this.state = {
      ...timelog,
      interval,
    };
    this.setDuration();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps.timelog,
    });
    this.setDuration();
  }

  setDuration() {
    const { endDate, startDate } = this.state;
    const now = moment().utc().format();
    const diff = moment.duration(
      moment(endDate || now).diff(moment(startDate))
    );
    let seconds = diff.asSeconds();
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;
    seconds %= 60;

    const duration = `${hours}:${minutes > 9 ? minutes : `0${minutes}`}:${
      seconds > 9 ? seconds : `0${seconds}`
    }`;
    this.state.duration = duration;
    this.setState({ duration });
  }

  async save(name, numeric) {
    const { utils, refresh, timelog } = this.props;

    if (timelog[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await utils.updateTimelog(timelog.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this Timelog, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { utils, timelog, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await utils.deleteTimelog(timelog.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Timelog has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      description,
      startDate,
      endDate,
      firstName,
      lastName,
      duration,
      loading,
    } = this.state;

    const tzName = moment.tz.guess();

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Tooltip title={`${firstName} ${lastName}`}>
            <Avatar>{`${firstName[0]}${lastName[0]}`}</Avatar>
          </Tooltip>
        </div>
        <div style={{ flexGrow: 1, padding: 8 }}>
          <TextField
            fullWidth
            disabled={loading}
            variant="outlined"
            value={description}
            onBlur={() => this.save("description")}
            onChange={(e) => this.setState({ description: e.target.value })}
            placeholder="What are you working on?"
          />
        </div>
        <div style={{ padding: 8 }}>
          <TextField
            variant="outlined"
            disabled={loading}
            type="datetime-local"
            label="Start"
            onBlur={() => this.save("startDate")}
            value={moment(startDate).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => {
              this.setState({
                startDate: moment.tz(e.target.value, tzName).utc().format(),
              });
            }}
          />
        </div>
        {endDate ? (
          <div style={{ padding: 8 }}>
            <TextField
              variant="outlined"
              type="datetime-local"
              disabled={loading}
              label="End"
              value={moment(endDate).format("YYYY-MM-DDTHH:mm")}
              onBlur={() => this.save("endDate")}
              onChange={(e) => {
                this.setState({
                  endDate: moment.tz(e.target.value, tzName).utc().format(),
                });
              }}
            />
          </div>
        ) : (
          []
        )}
        <div style={{ padding: 8 }}>{duration}</div>
        {!endDate ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              const ed = moment().utc().format();
              this.setState(
                {
                  endDate: ed,
                },
                () => this.save("endDate")
              );
            }}
          >
            Stop
          </Button>
        ) : (
          []
        )}
        <IconButton onClick={this.delete.bind(this)}>
          <Delete />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(Timelog);

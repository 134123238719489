// routes
// icons
import Clients from "@material-ui/icons/BusinessTwoTone";
import Projects from "@material-ui/icons/ComputerTwoTone";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoatTwoTone";
import Settings from "@material-ui/icons/SettingsTwoTone";
import Support from "@material-ui/icons/WatchLaterTwoTone";
import WidgetsIcon from "@material-ui/icons/WidgetsTwoTone";
import {
  CUSTOMERS,
  GIT_REPOS,
  PROJECT,
  SHIP_NODES,
  SUPPORT,
  SUPPORT_ITEM_TYPE,
} from "constants/routes";

export const menu = [
  {
    icon: WidgetsIcon,
    label: "Git Repos",
    path: GIT_REPOS,
    divider: true,
  },
  {
    icon: DirectionsBoatIcon,
    label: "Ship Nodes",
    path: SHIP_NODES,
    divider: true,
  },
  {
    icon: Clients,
    label: "Customers",
    path: CUSTOMERS,
    divider: true,
  },
  {
    icon: Projects,
    label: "Projects",
    path: PROJECT,
    divider: true,
  },
  {
    icon: Support,
    label: "Support",
    path: SUPPORT,
    divider: true,
  },
  {
    icon: Settings,
    label: "Global Settings",
    path: SUPPORT_ITEM_TYPE,
    divider: true,
  },
];

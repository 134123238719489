import { networkAction } from "helpers/network/networkAction";

import {
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
  GET_CUSTOMER_USERS,
  ADD_CUSTOMER_USER,
  DELETE_CUSTOMER_USER,
} from "constants/customers";

import * as Api from "api";

export const getCustomers = () => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMERS, Api.getCustomers, []);

export const getCustomerById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMER_BY_ID, Api.getCustomerById, [id]);

export const deleteCustomer = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CUSTOMER, Api.deleteCustomer, [id]);

export const createCustomer = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CUSTOMER, Api.createCustomer, [body]);

export const updateCustomer = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CUSTOMER, Api.updateCustomer, [id, body]);

export const getCustomerUsers = (customerID) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMER_USERS, Api.getCustomerUsers, [
    customerID,
  ]);

export const addCustomerUser = (customerID, uuid, jobTitle) => async (
  dispatch
) =>
  networkAction(dispatch, ADD_CUSTOMER_USER, Api.addCustomerUser, [
    customerID,
    uuid,
    jobTitle,
  ]);

export const deleteCustomerUser = (customerID, uuid) => async (dispatch) =>
  networkAction(dispatch, DELETE_CUSTOMER_USER, Api.deleteCustomerUser, [
    customerID,
    uuid,
  ]);

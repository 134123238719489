import { REACT_APP_API, REACT_APP_ID } from "config";

const token =
  "c7Vsz447O9uFfBqd2WrrPKeSmaPtzj6Wv8JcPmau6fahFM09i32JkOq5eDqga2Aj";

export default class Fetcher {
  get(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      credentials: "include",
      authServerToken: token,
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "GET",
    });
  }

  post(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      authServerToken: token,
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "POST",
    });
  }

  data(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: request.body,
      authServerToken: token,
      credentials: "include",
      headers: {
        "app-id": REACT_APP_ID,
      },
      method: "POST",
    });
  }

  delete(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      authServerToken: token,
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "DELETE",
    });
  }

  put(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      authServerToken: token,
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "PUT",
    });
  }
}

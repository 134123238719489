import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "support";
const supports = `${REACT_APP_API_PREFIX}/${service}/supports`;

export function getSupports(filters) {
  const url = `${supports}${filters || ""}`;
  return {
    method: GET,
    url,
  };
}

export function getSupportById(id) {
  const url = `${supports}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSupport(id, body) {
  const url = `${supports}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSupport(id) {
  const url = `${supports}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSupport(body) {
  const url = `${supports}`;
  return {
    method: POST,
    url,
    body,
  };
}

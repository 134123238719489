import { GET_SHIP_STAGES } from "constants/shipStages";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SHIP_STAGES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperProjectPage from "../component/WrapperProjectPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  projects: state.projects,
  customers: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: () => dispatch(Actions.getProjects()),
  getProjectById: (...args) => dispatch(Actions.getProjectById(...args)),
  createProject: (...args) => dispatch(Actions.createProject(...args)),
  deleteProject: (...args) => dispatch(Actions.deleteProject(...args)),
  updateProject: (...args) => dispatch(Actions.updateProject(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
});
class ProjectPage extends Component {
  static propTypes = {
    location: PropTypes.object,

    projects: PropTypes.array,
    customers: PropTypes.array,
    getProjects: PropTypes.func,
    getProjectById: PropTypes.func,
    createProject: PropTypes.func,
    deleteProject: PropTypes.func,
    updateProject: PropTypes.func,
    getCustomers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, projects } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: projects.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getProjects, getCustomers } = this.props;
    await Promise.all([getProjects(), getCustomers()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      projects,
      getProjectById,
      createProject,
      deleteProject,
      updateProject,
      customers,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperProjectPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        projects={projects}
        getProjectById={getProjectById}
        createProject={createProject}
        deleteProject={deleteProject}
        updateProject={updateProject}
        urlParams={urlParams}
        customers={customers}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);

export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const USERS = "/users";
export const PATH = "/paths";
export const DEBUG = "/debug";
export const SUPPORT = "/support";
export const CUSTOMERS = "/customers";
export const PROJECT = "/projects";
export const CUSTOMER = "/customer";
export const SUPPORT_ITEM_TYPE = "/global-settings";
export const GIT_REPOS = "/gitRepos";
export const SHIP_NODES = "/shipNodes";

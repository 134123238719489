import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormProject from "components/FormProject";
// components
import ProjectsList from "components/ProjectsList";
import PropTypes from "prop-types";
import React from "react";
import Page from "../../../components/Page";
// styles
import styles from "./styles";

class WrapperProjectPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    projects: PropTypes.array,
    createProject: PropTypes.func,
    deleteProject: PropTypes.func,
    updateProject: PropTypes.func,
    customers: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedProject: undefined,
      formProjectOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { projects } = this.props;

    switch (index) {
      case 0:
        return (
          <ProjectsList
            projects={projects}
            onSelect={(selectedProject) =>
              this.setState({
                selectedProject,
                formProjectOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createProject,
      deleteProject,
      updateProject,
      refresh,
      customers,
    } = this.props;

    const { index, selectedProject, formProjectOpen } = this.state;

    return (
      <Page
        helmet="Projects"
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formProjectOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
      >
        {this.getStepContent()}
        <FormProject
          open={formProjectOpen}
          project={selectedProject}
          close={() => {
            this.setState({
              formProjectOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedProject: undefined,
                }),
              200
            );
          }}
          createProject={createProject}
          deleteProject={deleteProject}
          updateProject={updateProject}
          customers={customers}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperProjectPage);

import { DEMO } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import DemoPage from "./container/DemoPage";

export default function getDemoRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.any],
  };
  return {
    path: DEMO,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: DemoPage,
  };
}

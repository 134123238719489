import { SHIP_NODES } from 'constants/routes';
import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import ShipNodesPage from './container/ShipNodesPage';

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [
      account.roles.superadmin,
    ],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: SHIP_NODES,
    withSidebar: true,
    withAppBar: true,
    component: ShipNodesPage,
  };
}

import * as Api from "api";
import {
  CREATE_SHIP_SERVICE,
  DELETE_SHIP_SERVICE,
  GET_SHIP_SERVICES,
  GET_SHIP_SERVICE_BY_ID,
  UPDATE_SHIP_SERVICE,
} from "constants/shipServices";
import { networkAction } from "helpers/network/networkAction";

export const getShipServices = () => async (dispatch) =>
  networkAction(dispatch, GET_SHIP_SERVICES, Api.getShipServices, []);

export const getShipServiceById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SHIP_SERVICE_BY_ID, Api.getShipServiceById, [id]);

export const deleteShipService = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SHIP_SERVICE, Api.deleteShipService, [id]);

export const createShipService = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SHIP_SERVICE, Api.createShipService, [body]);

export const updateShipService = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SHIP_SERVICE, Api.updateShipService, [
    id,
    body,
  ]);

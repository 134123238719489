import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import customers from "./customers";
import errors from "./errors";
import gitRepos from "./gitRepos";
import groups from "./groups";
import monthlySupports from "./monthlySupports";
import projects from "./projects";
import roles from "./roles";
import rules from "./rules";
import searchPresets from "./searchPresets";
import serviceNames from "./serviceNames";
import shipNodes from "./shipNodes";
import shipServices from "./shipServices";
import shipStages from "./shipStages";
import supportItems from "./supportItems";
import supportItemTypes from "./supportItemTypes";
import supports from "./supports";
import timelogs from "./timelogs";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
export default combineReducers({
  errors,
  user,
  app,
  users,
  groups,
  roles,
  rules,
  serviceNames,
  customers,
  projects,
  supports,
  monthlySupports,
  supportItems,
  supportItemTypes,
  userApps,
  timelogs,
  gitRepos,
  shipStages,
  shipServices,
  shipNodes,
  searchPresets,
});

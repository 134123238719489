import * as Api from "api";
import {
  CREATE_SHIP_STAGE,
  DELETE_SHIP_STAGE,
  GET_SHIP_STAGES,
  GET_SHIP_STAGE_BY_ID,
  UPDATE_SHIP_STAGE,
} from "constants/shipStages";
import { networkAction } from "helpers/network/networkAction";

export const getShipStages = () => async (dispatch) =>
  networkAction(dispatch, GET_SHIP_STAGES, Api.getShipStages, []);

export const getShipStageById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SHIP_STAGE_BY_ID, Api.getShipStageById, [id]);

export const deleteShipStage = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SHIP_STAGE, Api.deleteShipStage, [id]);

export const createShipStage = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SHIP_STAGE, Api.createShipStage, [body]);

export const updateShipStage = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SHIP_STAGE, Api.updateShipStage, [id, body]);

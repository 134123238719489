import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/AddCircleOutlined";
import DateRange from "@material-ui/icons/DateRange";
import Edit from "@material-ui/icons/Edit";
import Preview from "@material-ui/icons/RemoveRedEye";
// images
import supportImage from "assets/img/support.png";
import Clockify from "components/Clockify";
import FormMonthlySupport from "components/FormMonthlySupport";
import FormSupport from "components/FormSupport";
import FormSupportItem from "components/FormSupportItem";
// custom
import Loading from "components/Loading";
import SupportItem from "components/SupportItem";
import Timelog from "components/Timelog";
// constants
import { BAZAAR_ENDPOINT, REACT_APP_API } from "config";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Support extends Component {
  static propTypes = {
    classes: PropTypes.object,
    utils: PropTypes.object,
    customer: PropTypes.object,
    app: PropTypes.object,
    supportItemTypes: PropTypes.array,
    timelogs: PropTypes.array,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      supports: [],
      monthlySupports: [],
      loading: true,
      supportItems: [],
      supportFormOpen: false,
      supportItemFormOpen: false,
      selectedMonthlySupport: undefined,
      selectedSupportItem: undefined,
    };

    this.refresh();
  }

  async getSupportItems(monthlySupport) {
    const { utils } = this.props;
    const { getSupportItems } = utils;

    const filters = `?filters=[{"name": "monthlySupportID", "comparison": "eq", "value": ${monthlySupport.id}}]`;
    const resp = await getSupportItems(filters);

    if (resp.success) {
      this.setState({
        supportItems: resp.payload,
      });
    }
  }

  async refresh() {
    const { utils, customer } = this.props;

    const { getSupports, getMonthlySupports } = utils;

    let filters = `?filters=[{"name": "customerID", "comparison": "eq", "value": ${customer.id}}]`;
    const resp = await getSupports(filters);
    if (resp.success) {
      if (resp.payload.length) {
        filters = `?filters=[{"name": "supportID", "comparison": "eq", "value": ${resp.payload[0].id}}]&sorter=[{"name": "date", "asc": false}]`;
        const resp1 = await getMonthlySupports(filters);
        if (resp1.success) {
          const currentMonth = moment().format("MMMM YYYY");
          if (this.state.loading) {
            for (const k in resp1.payload) {
              if (resp1.payload.hasOwnProperty(k)) {
                const monthlySupport = resp1.payload[k];
                if (
                  moment(monthlySupport.date).format("MMMM YYYY") ===
                  currentMonth
                ) {
                  this.setState({
                    selectedMonthlySupport: monthlySupport,
                  });
                  utils.getMonthlySupportTimelogs(monthlySupport.id);
                  this.getSupportItems(monthlySupport);
                  break;
                }
              }
            }
          }

          this.setState({
            supports: resp.payload,
            monthlySupports: resp1.payload,
            loading: false,
          });
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  }

  closeSupport() {
    this.setState({ supportFormOpen: false });
  }

  openSupport() {
    this.setState({ supportFormOpen: true });
  }

  closeMonthlySupport() {
    this.setState({ supportMonthlyFormOpen: false });
  }

  openMonthlySupport() {
    this.setState({ supportMonthlyFormOpen: true });
  }

  closeSupportItem() {
    this.setState({ supportItemFormOpen: false });
  }

  openSupportItem() {
    this.setState({ supportItemFormOpen: true });
  }

  invoiceClicked() {
    const { utils } = this.props;
    const { selectedMonthlySupport } = this.state;
    if (selectedMonthlySupport) {
      if (!selectedMonthlySupport.invoiceID) {
        utils
          .createMonthlySupportInvoice(selectedMonthlySupport.id)
          .then(this.refresh.bind(this));
      } else {
        window.open(
          `${BAZAAR_ENDPOINT}/invoice?id=${selectedMonthlySupport.invoiceID}`,
          "_blank"
        );
      }
    }
  }

  render() {
    const {
      classes,
      utils,
      app,
      supportItemTypes,
      customer,
      timelogs,
      user,
    } = this.props;
    const {
      loading,
      supports,
      supportFormOpen,
      supportMonthlyFormOpen,
      selectedMonthlySupport,
      supportItemFormOpen,
      monthlySupports,
      supportItems,
      selectedSupportItem,
    } = this.state;

    const customerSupport = supports.length ? supports[0] : undefined;
    const selectedItemBackground = app.darkMode ? "#616161" : "#e3f2fd";

    if (loading) {
      return <Loading loading={loading} message={"Fetching Support Support"} />;
    }

    if (
      (loading === false && supports.length === 0) ||
      customerSupport === undefined
    ) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            textAlign: "center",
            height: "calc(100% - 200px)",
          }}
        >
          <Grid item xs={12}>
            <img src={supportImage} alt="support" height="150" />
            <br />
            <br />
            <Typography display="block" variant="h4">
              No Support Has Been Found.
            </Typography>
            <Typography display="block" variant="caption">
              Support is necessary to maintain your solution up to date and to
              &nbsp;have regular updates containing new features.
            </Typography>
            <br />
            <Button color="primary" onClick={this.openSupport.bind(this)}>
              Create Support
            </Button>
          </Grid>
          <FormSupport
            close={this.closeSupport.bind(this)}
            open={supportFormOpen}
            support={customerSupport}
            createSupport={utils.createSupport}
            updateSupport={utils.updateSupport}
            deleteSupport={utils.deleteSupport}
            refresh={this.refresh.bind(this)}
            customer={customer}
          />
        </Grid>
      );
    }

    const now = new Date();
    const next = now.setMonth(now.getMonth() + 1);

    const currentMonth = moment().format("MMMM YYYY");
    const nextMonth = moment(next).format("MMMM YYYY");

    return (
      <Grid container spacing={2} className={classes.container}>
        <Grid item sm="12" md="4">
          <Paper className={classes.paper}>
            <Chip
              icon={<Edit />}
              label="Edit"
              onClick={this.openSupport.bind(this)}
              className={classes.edit}
              variant="outlined"
            />
            <Grid
              container
              alignItems="center"
              justify="flex-start"
              spacing={32}
            >
              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <img src={supportImage} alt="support" height="80" />
                </div>
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  Support
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Description
                </Typography>
                <Typography display="block" variant="body2">
                  {customerSupport.description}
                </Typography>
                {customerSupport.startDate && customerSupport.startDate.length && (
                  <div>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      Start
                    </Typography>
                    <Typography display="block" variant="body2">
                      {moment(customerSupport.startDate).format("lll")}
                    </Typography>
                  </div>
                )}
                {customerSupport.endDate && customerSupport.endDate.length && (
                  <div>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      End
                    </Typography>
                    <Typography display="block" variant="body2">
                      {moment(customerSupport.endDate).format("lll")}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Chip
              icon={<Add />}
              label="New"
              onClick={() => {
                this.setState({
                  selectedMonthlySupport: undefined,
                });
                this.openMonthlySupport();
              }}
              className={classes.edit}
              variant="outlined"
            />
            <Typography display="block" variant="h6">
              Monthly Support
            </Typography>
            <List
              component="nav"
              style={{ maxHeight: 800, overflow: "scroll" }}
            >
              <Divider />
              {monthlySupports.map((ms) => [
                <ListItem
                  button
                  key={ms.id}
                  onClick={() => {
                    this.getSupportItems(ms);
                    this.setState({ selectedMonthlySupport: ms });
                    utils.getMonthlySupportTimelogs(ms.id);
                  }}
                  style={{
                    background:
                      selectedMonthlySupport &&
                      ms.id === selectedMonthlySupport.id
                        ? selectedItemBackground
                        : undefined,
                  }}
                >
                  <ListItemIcon>
                    <DateRange />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography display="block" variant="body2">
                      {`${moment(ms.date).format("MMMM YYYY")}`}
                    </Typography>
                    <Typography display="block" variant="overline">
                      {`$${(ms.amount / 100).toFixed(2)}`}
                    </Typography>
                    <Grid container spacing={1}>
                      {ms.paymentDate && (
                        <Grid item>
                          <Chip label="Paid" />
                        </Grid>
                      )}
                      {ms.invoicedDate && !ms.paid && (
                        <Grid item>
                          <Chip label="Invoiced" />
                        </Grid>
                      )}
                      {ms.overflowAllowed && (
                        <Grid item>
                          <Chip label="Overflow Allowed" />
                        </Grid>
                      )}
                    </Grid>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {nextMonth === `${moment(ms.date).format("MMMM YYYY")}` && (
                      <Chip
                        label="Next"
                        style={{ color: "white", background: "#e91e63" }}
                      />
                    )}
                    {currentMonth ===
                      `${moment(ms.date).format("MMMM YYYY")}` && (
                      <Chip
                        label="Current"
                        style={{ color: "white", background: "#2196f3" }}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>,
                <Divider key={`divider_${ms.id}`} />,
              ])}
            </List>
          </Paper>
        </Grid>
        {selectedMonthlySupport && (
          <Grid item sm="12" md="8">
            <Paper className={classes.paper}>
              <Chip
                icon={<Edit />}
                label="Edit"
                onClick={this.openMonthlySupport.bind(this)}
                className={classes.edit}
                variant="outlined"
              />
              <Typography display="block" variant="h6">
                {moment(selectedMonthlySupport.date).format("MMMM YYYY")}
              </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography display="block" variant="subtitle2">
                    Info
                  </Typography>
                  <Divider />
                  <Grid container spacing={2}>
                    {selectedMonthlySupport.description && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Description
                        </Typography>
                        <Typography display="block" variant="body1">
                          {selectedMonthlySupport.description}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.nextSteps && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Next Steps
                        </Typography>
                        <Typography display="block" variant="body1">
                          {selectedMonthlySupport.nextSteps}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.pdfURL && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Pdf URL
                        </Typography>
                        <Typography display="block" variant="body1">
                          {selectedMonthlySupport.pdfURL}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.cutOffDate && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Cut Off Date
                        </Typography>
                        <Typography display="block" variant="body1">
                          {moment(selectedMonthlySupport.cutOffDate).format(
                            "lll"
                          )}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography display="block" variant="subtitle2">
                    Payment
                  </Typography>
                  <Divider />
                  <Grid container spacing={2}>
                    {selectedMonthlySupport.overflowAllowed !== undefined && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Overflow
                        </Typography>
                        <Typography display="block" variant="body1">
                          {selectedMonthlySupport.overflowAllowed
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.amount !== undefined && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Amount
                        </Typography>
                        <Typography display="block" variant="body1">
                          {`$${(selectedMonthlySupport.amount / 100).toFixed(
                            2
                          )}`}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.invoicedDate && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Amount
                        </Typography>
                        <Typography display="block" variant="body1">
                          {moment(selectedMonthlySupport.invoicedDate).format(
                            "lll"
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.paymentDate && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Amount
                        </Typography>
                        <Typography display="block" variant="body1">
                          {moment(selectedMonthlySupport.paymentDate).format(
                            "lll"
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {selectedMonthlySupport.paymentType && (
                      <Grid item>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Amount
                        </Typography>
                        <Typography display="block" variant="body1">
                          {selectedMonthlySupport.paymentType}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <div
              style={{
                position: "relative",
                paddingLeftRight: 16,
              }}
            >
              <div>
                <Grid container spacing={2} justify="space-between">
                  <Grid item>
                    <Chip
                      style={{ background: "#f44336", color: "white" }}
                      disabled={selectedMonthlySupport.invoiceID}
                      label={
                        selectedMonthlySupport?.invoiceID
                          ? "View Invoice"
                          : "Create Invoice"
                      }
                      onClick={this.invoiceClicked.bind(this)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      icon={<Preview />}
                      label="Preview PDF"
                      onClick={() =>
                        window.open(
                          `${REACT_APP_API}/support/monthlySupports/${selectedMonthlySupport.id}/preview`,
                          "_blank"
                        )
                      }
                      variant="outlined"
                      style={{ marginRight: 8 }}
                    />
                    <Chip
                      icon={<Add />}
                      label="New"
                      onClick={this.openSupportItem.bind(this)}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                {supportItems.map((si) => (
                  <Grid item key={si.id} xs={12} sm={6} md={4}>
                    <div
                      onClick={() => {
                        this.setState({ selectedSupportItem: si });
                        this.openSupportItem();
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <SupportItem
                        {...si}
                        supportItemType={supportItemTypes.find(
                          (sit) => sit.id === si.supportTypeID
                        )}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <Clockify
              customerSupport={customerSupport}
              user={user}
              utils={utils}
              refresh={() =>
                utils.getMonthlySupportTimelogs(selectedMonthlySupport.id)
              }
              customer={customer}
            />
            <Paper style={{ padding: 10, marginTop: 20 }}>
              {timelogs &&
                timelogs.map((t) => (
                  <Timelog
                    key={t.id}
                    utils={utils}
                    refresh={() =>
                      utils.getMonthlySupportTimelogs(selectedMonthlySupport.id)
                    }
                    timelog={t}
                  />
                ))}
              {timelogs.length === 0 ? (
                <Typography display="block" variant="body2">
                  No Logs
                </Typography>
              ) : (
                []
              )}
            </Paper>
          </Grid>
        )}
        <FormSupport
          close={this.closeSupport.bind(this)}
          open={supportFormOpen}
          support={customerSupport}
          createSupport={utils.createSupport}
          updateSupport={utils.updateSupport}
          deleteSupport={utils.deleteSupport}
          refresh={this.refresh.bind(this)}
          customer={customer}
        />
        <FormSupportItem
          close={this.closeSupportItem.bind(this)}
          open={supportItemFormOpen}
          supportItem={selectedSupportItem}
          monthlySupport={selectedMonthlySupport}
          createSupportItem={utils.createSupportItem}
          updateSupportItem={utils.updateSupportItem}
          deleteSupportItem={(...args) => {
            this.setState({ selectedSupportItem: undefined });
            return utils.deleteSupportItem(...args);
          }}
          refresh={() => this.getSupportItems(selectedMonthlySupport)}
          supportItemTypes={supportItemTypes}
        />
        <FormMonthlySupport
          customerSupport={customerSupport}
          close={this.closeMonthlySupport.bind(this)}
          open={supportMonthlyFormOpen}
          monthlySupport={selectedMonthlySupport}
          createMonthlySupport={utils.createMonthlySupport}
          updateMonthlySupport={utils.updateMonthlySupport}
          deleteMonthlySupport={utils.deleteMonthlySupport}
          refresh={this.refresh.bind(this)}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(Support);

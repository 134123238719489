import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class TableAvatar extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <img
        alt="customer-logo"
        src={datum && datum.logo ? datum.logo : ""}
        style={{
          height: 40,
        }}
      />
    );
  }
}

export default withStyles(styles)(TableAvatar);

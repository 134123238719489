import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// custom
import Table from "components/Table";

// styles
import styles from "./styles";

class UsersList extends React.Component {
  static propTypes = {
    users: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedUser) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedUser);
    }
  }

  render() {
    const { users } = this.props;

    return (
      <Table
        data={users}
        meta={[
          {
            path: "firstName",
            title: "First Name",
            numeric: false,
            hidden: { mdDown: true },
          },
          {
            path: "lastName",
            title: "Last Name",
            numeric: false,
            hidden: { mdDown: true },
          },
          {
            path: "email",
            title: "Email",
            numeric: false,
          },
        ]}
        title={"Users"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(UsersList);

import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/Table";
import TableAvatar from "components/TableAvatar";
import TableCustomerEdit from "components/TableCustomerEdit";

// styles
import styles from "./styles";

class CustomersList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    customers: PropTypes.array,
    onSelect: PropTypes.func,
    onCustomerUsers: PropTypes.func,
    onEdit: PropTypes.func,
  };

  onSelect(selectedCustomer) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCustomer);
    }
  }

  onCustomerUsersClick(selectedCustomer) {
    const { onCustomerUsers } = this.props;
    if (onCustomerUsers) {
      onCustomerUsers(selectedCustomer);
    }
  }

  render() {
    const { customers, onEdit } = this.props;

    return (
      <Table
        data={customers}
        meta={[
          {
            path: "id",
            title: "",
            numeric: false,
            component: TableAvatar,
            width: 50,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "contactName",
            title: "Contact",
            numeric: false,
          },
          {
            path: "contactPhone",
            title: "Phone",
            numeric: false,
          },
          {
            path: "contactEmail",
            title: "Email",
            numeric: false,
          },
          {
            path: "id",
            title: "Edit",
            numeric: false,
            component: TableCustomerEdit,
            inject: {
              onEdit,
            },
          },
        ]}
        title={"Customers"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(CustomersList);

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Help from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SupportItem extends Component {
  static propTypes = {
    classes: PropTypes.object,
    currentValue: PropTypes.number,
    editable: PropTypes.bool,
    extraRate: PropTypes.number,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    recommendedValue: PropTypes.number,
    setDate: PropTypes.string,
    setDateUserUID: PropTypes.string,
    setValue: PropTypes.number,
    supportItemType: PropTypes.object,
    unit: PropTypes.string,
  };

  render() {
    const { supportItemType, currentValue, unit, setValue } = this.props;
    const { description, name, color, icon } = supportItemType;

    return (
      <div
        style={{
          marginTop: 50,
          marginLeft: 12,
          marginRight: 12,
          position: "relative",
        }}
      >
        <Paper
          style={{
            position: "absolute",
            height: 100,
            width: 100,
            background: color,
            marginLeft: 20,
            zIndex: 999,
            marginTop: -30,
          }}
        >
          <Grid
            container
            style={{ width: "100%", height: "100%" }}
            justify="center"
            alignItems="center"
          >
            <img src={icon} alt="img" />
          </Grid>
        </Paper>
        <Paper
          style={{
            position: "relative",
            minHeight: 100,
          }}
        >
          <div
            style={{
              marginLeft: 130,
              width: "calc(100% - 150px)",
              padding: 10,
              textAlign: "right",
            }}
          >
            <Typography display="block" variant="h6">
              {name}
            </Typography>
            <Typography display="block" variant="h3">
              {currentValue / 100}
            </Typography>
            <Typography display="block" variant="caption">
              /{setValue} {unit}
            </Typography>
          </div>
          <Grid container justify="space-between" style={{ padding: 10 }}>
            <Grid item>
              <Typography display="block" variant="body1">
                {Math.floor(currentValue / setValue)}% Consumed
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={description}>
                <Help color="disabled" style={{ cursor: "pointer" }} />
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(SupportItem);

import * as Api from "api";
import {
  CREATE_MONTHLY_SUPPORT,
  DELETE_MONTHLY_SUPPORT,
  GET_MONTHLY_SUPPORTS,
  GET_MONTHLY_SUPPORT_BY_ID,
  GET_MONTHLY_SUPPORT_INVOICE,
  GET_MONTHLY_SUPPORT_TIMELOGS,
  PREVIEW_MONTHLY_SUPPORT,
  UPDATE_MONTHLY_SUPPORT,
} from "constants/monthlySupports";
import { networkAction } from "helpers/network/networkAction";

export const getMonthlySupportTimelogs = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_MONTHLY_SUPPORT_TIMELOGS,
    Api.getMonthlySupportTimelogs,
    [id]
  );

export const getMonthlySupports = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_MONTHLY_SUPPORTS, Api.getMonthlySupports, [
    filters,
  ]);

export const getMonthlySupportById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_MONTHLY_SUPPORT_BY_ID,
    Api.getMonthlySupportById,
    [id]
  );

export const createMonthlySupportInvoice = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_MONTHLY_SUPPORT_INVOICE,
    Api.createMonthlySupportInvoice,
    [id]
  );

export const previewMonthlySupport = (id) => async (dispatch) =>
  networkAction(dispatch, PREVIEW_MONTHLY_SUPPORT, Api.previewMonthlySupport, [
    id,
  ]);

export const deleteMonthlySupport = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MONTHLY_SUPPORT, Api.deleteMonthlySupport, [
    id,
  ]);

export const createMonthlySupport = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MONTHLY_SUPPORT, Api.createMonthlySupport, [
    body,
  ]);

export const updateMonthlySupport = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MONTHLY_SUPPORT, Api.updateMonthlySupport, [
    id,
    body,
  ]);

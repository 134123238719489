import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormProject extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    project: PropTypes.object,
    customers: PropTypes.array,

    createProject: PropTypes.func,
    updateProject: PropTypes.func,
    deleteProject: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      description: "",
      supportActive: "",
      customerID: "",
      logo: "",
      timelogID: "",
      startDate: "",
      endDate: "",
      supportStartDate: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.project);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(project) {
    this.setState({
      loading: false,
      name: project ? project.name : "",
      description: project ? project.description : "",
      supportActive: project ? project.supportActive : "",
      customerID: project ? project.customerID : "",
      logo: project ? project.logo : "",
      timelogID: project ? project.timelogID : "",
      startDate: project ? project.startDate : "",
      endDate: project ? project.endDate : "",
      supportStartDate: project ? project.supportStartDate : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this project, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteProject, close, refresh, project } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteProject(project.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Project has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateProject,
      createProject,
      project,
      refresh,
      close,
    } = this.props;

    const {
      name,
      loading,
      supportActive,
      customerID,
      description,
      logo,
      timelogID,
      startDate,
      endDate,
      supportStartDate,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (project) {
      resp = await updateProject(project.id, {
        name,
        loading,
        supportActive,
        customerID,
        description,
        logo,
        timelogID,
        startDate,
        endDate,
        supportStartDate,
      });
    } else {
      resp = await createProject({
        name,
        loading,
        supportActive,
        customerID,
        description,
        logo,
        timelogID,
        startDate,
        endDate,
        supportStartDate,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: project
          ? "Project has been updated."
          : "Project has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, project, close, customers } = this.props;

    const {
      name,
      loading,
      supportActive,
      customerID,
      description,
      logo,
      timelogID,
      startDate,
      endDate,
      supportStartDate,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {project ? `${project.name}` : "Let's Create a New Project"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {project
                  ? "To update the project, fill the form bellow :"
                  : "To create a new project, fill the form bellow :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="customer-simple">
                      Select the Customer
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "Select the Customer",
                        id: "customer-simple",
                      }}
                      value={customerID}
                      onChange={(e) =>
                        this.setState({ customerID: e.target.value })
                      }
                    >
                      {customers.map((c) => (
                        <MenuItem key={`customerID_${c.id}`} value={c.id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description *"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="logo"
                    label="Logo *"
                    className={classes.textField}
                    value={logo}
                    onChange={this.handleChange("logo")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="timelogID"
                    label="Timelog ID *"
                    className={classes.textField}
                    value={timelogID}
                    onChange={this.handleChange("timelogID")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="startDate"
                    label="Start Date *"
                    className={classes.textField}
                    value={startDate}
                    onChange={this.handleChange("startDate")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="endDate"
                    label="End Date *"
                    className={classes.textField}
                    value={endDate}
                    onChange={this.handleChange("endDate")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={supportActive}
                        onChange={this.handleCheckboxChange("supportActive")}
                        value="dflt"
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </Grid>
                {supportActive && (
                  <Grid item xs={12}>
                    <TextField
                      id="supportStartDate"
                      label="Support Start Date *"
                      type="date"
                      className={classes.textField}
                      value={supportStartDate}
                      onChange={this.handleChange("supportStartDate")}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {project && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {project ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormProject));

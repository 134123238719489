// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperShipNodesPage from "../component/WrapperShipNodesPage";

const mapStateToProps = (state) => ({
  gitRepos: state.gitRepos,
  shipNodes: state.shipNodes,
  shipStages: state.shipStages,
  shipServices: state.shipServices,
});

const mapDispatchToProps = (dispatch) => ({
  getGitRepos: () => dispatch(Actions.getGitRepos()),
  getShipNodes: () => dispatch(Actions.getShipNodes()),
  getShipStages: () => dispatch(Actions.getShipStages()),
  getShipServices: () => dispatch(Actions.getShipServices()),
  getGitRepoById: (...args) => dispatch(Actions.getGitRepoById(...args)),
  createGitRepo: (...args) => dispatch(Actions.createGitRepo(...args)),
  deleteGitRepo: (...args) => dispatch(Actions.deleteGitRepo(...args)),
  updateGitRepo: (...args) => dispatch(Actions.updateGitRepo(...args)),
  getShipNodeById: (...args) => dispatch(Actions.getShipNodeById(...args)),
});
class GitReposPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    gitRepos: PropTypes.array,
    shipNodes: PropTypes.array,
    shipStages: PropTypes.array,
    shipServices: PropTypes.array,

    getGitRepos: PropTypes.func,
    getGitRepoById: PropTypes.func,
    createGitRepo: PropTypes.func,
    deleteGitRepo: PropTypes.func,
    updateGitRepo: PropTypes.func,
    getShipNodeById: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, gitRepos } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: gitRepos.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const {
      getGitRepos,
      getShipServices,
      getShipStages,
      getShipNodes,
    } = this.props;
    await Promise.all([
      getGitRepos(),
      getShipServices(),
      getShipStages(),
      getShipNodes(),
    ]);

    this.setState({ loading: false, refreshKey: new Date().getTime() });
  }

  render() {
    const {
      gitRepos,
      getGitRepoById,
      createGitRepo,
      deleteGitRepo,
      updateGitRepo,
      shipNodes,
      getShipNodeById,
      history,
    } = this.props;

    const { urlParams, loading, refreshKey } = this.state;

    return (
      <WrapperShipNodesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        shipNodes={shipNodes}
        key={refreshKey}
        history={history}
        gitRepos={gitRepos}
        getGitRepoById={getGitRepoById}
        getShipNodeById={getShipNodeById}
        createGitRepo={createGitRepo}
        deleteGitRepo={deleteGitRepo}
        updateGitRepo={updateGitRepo}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GitReposPage);

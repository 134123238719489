export default (theme) => ({
  container: {
    padding: 20,
  },
  paper: {
    padding: 10,
    position: "relative",
    marginBottom: 16,
  },
  edit: {
    position: "absolute",
    top: 10,
    right: 10,
  },
});

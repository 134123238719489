import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormMonthlySupport extends Component {
  static propTypes = {
    customerSupport: PropTypes.object,
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    monthlySupport: PropTypes.object,

    createMonthlySupport: PropTypes.func,
    updateMonthlySupport: PropTypes.func,
    deleteMonthlySupport: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      amount: "",
      invoicedDate: "",
      paymentDate: "",
      paymentType: "",
      date: "",
      description: "",
      nextSteps: "",
      overflowAllowed: false,
      pdfURL: "",
      cutOffDate: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.monthlySupport);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(monthlySupport) {
    this.setState({
      loading: false,
      amount: monthlySupport ? monthlySupport.amount / 100 : "",
      invoicedDate: monthlySupport ? monthlySupport.invoicedDate : "",
      paymentDate: monthlySupport ? monthlySupport.paymentDate : "",
      paymentType: monthlySupport ? monthlySupport.paymentType : "",
      date: monthlySupport ? monthlySupport.date : "",
      description: monthlySupport ? monthlySupport.description : "",
      nextSteps: monthlySupport ? monthlySupport.nextSteps : "",
      overflowAllowed: monthlySupport ? monthlySupport.overflowAllowed : false,
      pdfURL: monthlySupport ? monthlySupport.pdfURL : "",
      cutOffDate: monthlySupport ? monthlySupport.cutOffDate : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this monthlySupport, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteMonthlySupport, group, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteMonthlySupport(group.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "MonthlySupport has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateMonthlySupport,
      createMonthlySupport,
      monthlySupport,
      customerSupport,
      close,
      refresh,
    } = this.props;

    const {
      amount,
      invoicedDate,
      paymentDate,
      paymentType,
      date,
      description,
      nextSteps,
      overflowAllowed,
      pdfURL,
      cutOffDate,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (monthlySupport) {
      resp = await updateMonthlySupport(monthlySupport.id, {
        amount: Number(amount) * 100,
        invoicedDate,
        paymentDate,
        paymentType,
        date,
        description,
        nextSteps,
        overflowAllowed,
        pdfURL,
        cutOffDate,
      });
    } else {
      resp = await createMonthlySupport({
        supportID: customerSupport.id,
        amount: Number(amount) * 100,
        invoicedDate,
        paymentDate,
        paymentType,
        date,
        description,
        nextSteps,
        overflowAllowed,
        pdfURL,
        cutOffDate,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: monthlySupport
          ? "Monthly Support has been updated."
          : "Monthly Support has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, monthlySupport, close } = this.props;

    const {
      amount,
      invoicedDate,
      paymentDate,
      paymentType,
      date,
      description,
      nextSteps,
      overflowAllowed,
      pdfURL,
      cutOffDate,
      loading,
    } = this.state;

    console.log(monthlySupport);

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {monthlySupport
                ? moment(date).format("MMMM YYYY")
                : "Let's Create a New Monthly Support"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {monthlySupport
                  ? "To update the Monthly Support, fill the form bellow :"
                  : "To create a new Monthly Support, fill the form bellow :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography display="block" variant="h6">
                    Info
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="date"
                    type="date"
                    label="Date"
                    className={classes.textField}
                    value={date}
                    onChange={this.handleChange("date")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="Description"
                    multiline
                    label="Description"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="nextSteps"
                    multiline
                    label="Next Steps"
                    className={classes.textField}
                    value={nextSteps}
                    onChange={this.handleChange("nextSteps")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="Pdf URL"
                    label="PDF URL"
                    className={classes.textField}
                    value={pdfURL}
                    onChange={this.handleChange("pdfURL")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="cutOffDate"
                    type="date"
                    defaultValue={moment(cutOffDate).format("YYYY-MM-DD")}
                    label="Cut Off Date"
                    className={classes.textField}
                    onChange={this.handleChange("cutOffDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography display="block" variant="h6">
                    Payment
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-adornment-amount"
                    className={classes.textField}
                    label="Amount"
                    value={amount}
                    onChange={this.handleChange("amount")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={overflowAllowed}
                        onChange={this.handleCheckboxChange("overflowAllowed")}
                        value="dflt"
                        color="primary"
                      />
                    }
                    label="Overflow Allowed"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="invoicedDate"
                    type="date"
                    label="Invoiced Date"
                    className={classes.textField}
                    defaultValue={moment(invoicedDate).format("YYYY-MM-DD")}
                    onChange={this.handleChange("invoicedDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="paymentDate"
                    type="date"
                    label="Payment Date"
                    className={classes.textField}
                    defaultValue={moment(paymentDate).format("YYYY-MM-DD")}
                    onChange={this.handleChange("paymentDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="paymentType"
                    label="Payment Type"
                    className={classes.textField}
                    value={paymentType}
                    onChange={this.handleChange("paymentType")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {monthlySupport && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {monthlySupport ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormMonthlySupport));

export const routeNames = [
  "Default",
  "MyAccount",
  "Signin",
  "Demo",
  "Users",
  "Debug",
  "Support",
  "Customers",
  "Project",
  "Customer",
  "SupportItemType",
  "GitRepos",
  "ShipNodes",
];

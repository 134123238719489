import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
// custom
import AppBar from "components/AppBar";
// reactor
import AuthChecker from "components/AuthChecker";
import EnableCookieMessage from "components/EnableCookieMessage";
import ForgotPassword from "components/ForgotPassword";
import KeyboardEvents from "components/KeyboardEvents";
import LeftMenu from "components/LeftMenu";
import MessageDrawer from "components/MessageDrawer";
import NotificationCenter from "components/NotificationCenter";
import ResetPassword from "components/ResetPassword";
import Signup from "components/Signup";
import Upload from "components/Upload";
import UrlActions from "components/UrlActions";
import { GOOGLE_ANALYTICS_KEY, REACT_APP_ROOT_BACKGROUND } from "config";
import { SIGNIN } from "constants/routes";
// helpers
import { redirectAccordingToRole } from "helpers/redirect";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// styles
import styles from "./styles";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,
    allRoutes: PropTypes.array,
    cookies: PropTypes.any,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.array,

    // Api
    getCurrentUser: PropTypes.func,
    websocketMessageReceived: PropTypes.func,
    init: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    upload: PropTypes.func,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    stopImpersonate: PropTypes.func,
  };

  static childContextTypes = {
    appBarMenuHook: PropTypes.func,
    onAppLogoClickedHook: PropTypes.func,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      mobileOpen: false,
      anchorEl: null,
      miniActive: false,
      accountOpen: false,
      loading: true,
      alert: null,
      vh: 0,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  getChildContext() {
    return {
      appBarMenuHook: (e) => this.setState({ appBarMenuHook: e }),
      onAppLogoClickedHook: (e) => this.setState({ onAppLogoClickedHook: e }),
    };
  }

  componentDidMount() {
    this.refreshInnerHeight();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  refreshInnerHeight() {
    // We execute the same script as before
    console.log("refreshInnerHeight");

    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div style={{ flexGrow: 1, display: "flex", position: "relative" }}>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      init,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      signout,
      storeResetPasswordToken,
      app,
      resetPassword,
      allRoutes,
      user,
      theme,
      setDarkMode,
      stopImpersonate,
      userApps,
      setOrganizationByDefault,
      websocketMessageReceived,
      joinOrganization,
      upload,
    } = this.props;

    const { loading, alert, mobileOpen } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    return (
      <div className="root">
        <AuthChecker
          routes={allRoutes}
          history={history}
          location={location}
          user={user}
          getCurrentUser={getCurrentUser}
          onReady={() => {
            this.setState({ loading: false });
          }}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          init={init}
        >
          <NotificationCenter errors={errors}>
            {/* <Centrifugo websocketMessageReceived={websocketMessageReceived}> */}
            <KeyboardEvents>
              <Signup
                history={history}
                signup={signup}
                validateEmail={validateEmail}
              >
                <UrlActions
                  location={location}
                  pathname={`${location.pathname}`}
                  history={history}
                  validateEmail={validateEmail}
                  user={user}
                  loading={loading}
                  storeResetPasswordToken={storeResetPasswordToken}
                >
                  <ForgotPassword
                    history={history}
                    requestResetPassword={requestResetPassword}
                  >
                    <ResetPassword
                      token={app.resetPasswordToken}
                      resetPassword={resetPassword}
                      storeResetPasswordToken={storeResetPasswordToken}
                    >
                      <Upload upload={upload}>
                        {loading ? (
                          <Grid
                            container
                            className={classes.loading}
                            alignContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <CircularProgress style={{ color: "#ffffff" }} />
                            </Grid>
                          </Grid>
                        ) : (
                          <MessageDrawer background={REACT_APP_ROOT_BACKGROUND}>
                            <div
                              style={{
                                display: "flex",
                                flexGrow: 1,
                              }}
                            >
                              {route && route.withSidebar && (
                                <LeftMenu
                                  routes={routes}
                                  handleDrawerToggle={this.handleDrawerToggle.bind(
                                    this
                                  )}
                                  mobileOpen={mobileOpen}
                                  history={history}
                                  user={user}
                                  location={location}
                                />
                              )}
                              <div
                                style={{
                                  flexGrow: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {route && route.withAppBar && (
                                  <AppBar
                                    onMenuOpen={() => {
                                      this.handleDrawerToggle();
                                      if (this.state.appBarMenuHook) {
                                        this.state.appBarMenuHook();
                                      }
                                    }}
                                    onAppLogoClicked={() => {
                                      if (this.state.onAppLogoClickedHook) {
                                        this.state.onAppLogoClickedHook();
                                      }
                                    }}
                                    user={user}
                                    userApps={userApps}
                                    history={history}
                                    app={app}
                                    setDarkMode={setDarkMode}
                                    stopImpersonate={stopImpersonate}
                                    setOrganizationByDefault={
                                      setOrganizationByDefault
                                    }
                                    joinOrganization={joinOrganization}
                                    getCurrentUser={getCurrentUser}
                                    signout={signout}
                                  />
                                )}
                                {this.injectRoutes()}
                                {alert}
                              </div>
                            </div>
                          </MessageDrawer>
                        )}
                      </Upload>
                    </ResetPassword>
                  </ForgotPassword>
                </UrlActions>
              </Signup>
            </KeyboardEvents>
            {/* </Centrifugo> */}
          </NotificationCenter>
        </AuthChecker>
        <EnableCookieMessage
          open={!navigator.cookieEnabled}
          close={() => this.setState({ enableCookie: false })}
        />
      </div>
    );
  }
}

export default withCookies(
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  )
);

import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormGitRepo from "components/FormGitRepo";
// components
import GitReposList from "components/GitReposList";
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperGitReposPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //gitRepos
    gitRepos: PropTypes.array,
    getGitRepoById: PropTypes.func,
    createGitRepo: PropTypes.func,
    deleteGitRepo: PropTypes.func,
    updateGitRepo: PropTypes.func,
    starGitRepo: PropTypes.func,
    protectGitRepo: PropTypes.func,
    forceUpdateGitRepo: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedGitRepo: undefined,
      formGitRepoOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      gitRepos,
      starGitRepo,
      protectGitRepo,
      forceUpdateGitRepo,
      history,
      getGitRepos,
      refresh,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <GitReposList
            gitRepos={gitRepos}
            starGitRepo={starGitRepo}
            protectGitRepo={protectGitRepo}
            forceUpdateGitRepo={forceUpdateGitRepo}
            refresh={refresh}
            getGitRepos={getGitRepos}
            history={history}
            onSelect={(selectedGitRepo) =>
              window.open(
                `https://git.wid.la/projects/${selectedGitRepo.gitlabID}`,
                "_blank"
              )
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createGitRepo,
      deleteGitRepo,
      updateGitRepo,
      refresh,
    } = this.props;

    const { index, selectedGitRepo, formGitRepoOpen } = this.state;

    return (
      <Page
        helmet={"Git Repos"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formGitRepoOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="GitRepos"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormGitRepo
          open={formGitRepoOpen}
          gitRepo={selectedGitRepo}
          close={() => {
            this.setState({
              formGitRepoOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedGitRepo: undefined,
                }),
              200
            );
          }}
          createGitRepo={createGitRepo}
          deleteGitRepo={deleteGitRepo}
          updateGitRepo={updateGitRepo}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperGitReposPage);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// component
import WrapperDebugPage from "../component/WrapperDebugPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class DebugPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  render() {
    const { location, history, user } = this.props;

    const { urlParams } = this.state;

    return (
      <WrapperDebugPage
        history={history}
        location={location}
        urlParams={urlParams}
        user={user}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DebugPage);

import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "arranger";
const customers = `${REACT_APP_API_PREFIX}/${service}/customers`;

export function getCustomers() {
  const url = `${customers}`;
  return {
    method: GET,
    url,
  };
}

export function getCustomerById(id) {
  const url = `${customers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCustomer(id, body) {
  const url = `${customers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCustomer(id) {
  const url = `${customers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCustomer(body) {
  const url = `${customers}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getCustomerUsers(customerID) {
  const url = `${customers}/${customerID}/users`;
  return {
    method: GET,
    url,
  };
}

export function addCustomerUser(customerID, uuid, jobTitle) {
  const url = `${customers}/${customerID}/users`;
  return {
    method: POST,
    url,
    body: {
      userUID: uuid,
      jobTitle,
    },
  };
}

export function deleteCustomerUser(customerID, uuid) {
  const url = `${customers}/${customerID}/users`;
  return {
    method: DELETE,
    url,
    body: {
      userUID: uuid,
    },
  };
}

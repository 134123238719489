import { SUPPORT } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import SupportPage from "./container/SupportPage";

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.customer],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: SUPPORT,
    withSidebar: true,
    withAppBar: true,
    component: SupportPage,
  };
}

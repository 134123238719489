import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Clockify extends Component {
  static propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    utils: PropTypes.object,
    customerSupport: PropTypes.object,
    refresh: PropTypes.func,
  };

  state = {
    description: "",
  };

  async createTimelog() {
    const { utils, user, customerSupport, refresh } = this.props;
    const { description } = this.state;
    const resp = await utils.createTimelog({
      projectID: customerSupport.projectID,
      userUID: user.uuid,
      supportItemTypeID: 1,
      description,
      startDate: moment().utc().format(),
    });
    if (resp.success) {
      refresh();
      this.setState({ description: "" });
    }
  }

  render() {
    const { classes } = this.props;
    const { description } = this.state;

    return (
      <Paper className={classes.container}>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              value={description}
              onChange={(e) => this.setState({ description: e.target.value })}
              placeholder="What are you working on?"
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="raised"
              onClick={this.createTimelog.bind(this)}
            >
              Start
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Clockify);

import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// components
import CustomersList from "components/CustomersList";
import FormCustomer from "components/FormCustomer";
// routes
import { CUSTOMER } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import Page from "../../../components/Page";
// styles
import styles from "./styles";

class WrapperCustomerPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    customers: PropTypes.array,
    createCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    getCustomerUsers: PropTypes.func,
    addCustomerUser: PropTypes.func,
    deleteCustomerUser: PropTypes.func,
    users: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCustomer: undefined,
      formCustomerOpen: false,
      customerUsersOpen: false,
    };
  }

  onCustomerUsers(selectedCustomer) {
    this.setState({ selectedCustomer });
    this.openCustomerUsers();
  }

  getStepContent() {
    const { index } = this.state;
    const { customers, history } = this.props;

    switch (index) {
      case 0:
        return (
          <CustomersList
            customers={customers}
            onSelect={(customer) =>
              history.push(`${CUSTOMER}?customerID=${customer.id}`)
            }
            onCustomerUsers={this.onCustomerUsers.bind(this)}
            onEdit={(selectedCustomer) => {
              this.setState({
                selectedCustomer,
                formCustomerOpen: true,
              });
            }}
          />
        );
      default:
    }

    return [];
  }

  openCustomerUsers() {
    this.setState({ customerUsersOpen: true });
  }

  closeCustomerUsers() {
    this.setState({ customerUsersOpen: false });
  }

  render() {
    const {
      classes,
      history,
      loading,
      createCustomer,
      deleteCustomer,
      updateCustomer,
      refresh,
    } = this.props;

    const { index, selectedCustomer, formCustomerOpen } = this.state;

    return (
      <Page
        helmet="Customers"
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formCustomerOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormCustomer
          open={formCustomerOpen}
          customer={selectedCustomer}
          close={() => {
            this.setState({
              formCustomerOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCustomer: undefined,
                }),
              200
            );
          }}
          createCustomer={createCustomer}
          deleteCustomer={deleteCustomer}
          updateCustomer={updateCustomer}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCustomerPage);

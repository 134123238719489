import AppBar from "@material-ui/core/AppBar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import FormPersonalInfo from "components/FormPersonalInfo";
// custom
import FormResetPassword from "components/FormResetPassword";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

class WrapperMyAccountPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    refresh: PropTypes.func,
    updateUserPassword: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      updateUserPassword,
      updateUser,
      user,
      classes,
      refresh,
    } = this.props;

    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography display="block" variant="h6" gutterBottom noWrap>
                Your Personal Data
              </Typography>
              <Typography display="block" variant="body2">
                You can here update your personal information such as your first
                and last name, your email and change your password
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormPersonalInfo
                    refresh={refresh}
                    user={user}
                    updateUser={updateUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormResetPassword
                    user={user}
                    updateUserPassword={updateUserPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography display="block" variant="h6" gutterBottom>
                      Other
                    </Typography>
                    <Grid container>
                      <Typography display="block" variant="body2">
                        Uuid
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.uuid}
                        </Typography>
                      </Grid>
                      <Typography display="block" variant="body2">
                        Created At
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.createdAt}
                        </Typography>
                      </Grid>
                      <Typography display="block" variant="body2">
                        Updated At
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.updatedAt}
                        </Typography>
                      </Grid>
                      <Typography display="block" variant="body2">
                        Id
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography display="block" variant="h6" gutterBottom>
                      Services & Roles
                    </Typography>
                    {Object.keys(user.services).map((k) => (
                      <div key={`service_${k}`}>
                        <Typography display="block" variant="body2">
                          {k}
                        </Typography>
                        {user.services[k].map((r) => (
                          <Chip
                            key={`role_${k}_${r}`}
                            label={r}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Typography display="block" variant="h6">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history } = this.props;

    const { index } = this.state;

    return (
      <div className={`${classes.background} page`}>
        <Helmet>
          <title>My Account</title>
          <meta name="MyAccount" content="MyAccount page" />
        </Helmet>
        <div>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="My Account"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </AppBar>
          <div className="container withAppBarAndTabs">
            {this.getStepContent()}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperMyAccountPage);

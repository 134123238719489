import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";

// components
import UsersList from "components/UsersList";
import Loading from "components/Loading";
import FormUser from "components/FormUser";

// styles
import styles from "./styles";

class WrapperUsersPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    users: PropTypes.array,
    createUser: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    groups: PropTypes.array,
    getUserGroups: PropTypes.func,
    createUserGroup: PropTypes.func,
    deleteUserGroup: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedUser: undefined,
      formUserOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { users } = this.props;

    switch (index) {
      case 0:
        return (
          <UsersList
            users={users}
            onSelect={(selectedUser) =>
              this.setState({
                selectedUser,
                formUserOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createUser,
      deleteUser,
      updateUser,
      groups,
      getUserGroups,
      createUserGroup,
      deleteUserGroup,
      refresh,
    } = this.props;

    const { index, selectedUser, formUserOpen } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {loading ? (
          <Loading message={"Fetching all Users"} />
        ) : (
          <div>
            <Helmet>
              <title>Users</title>
              <meta name="MyAccount" content="MyAccount page" />
            </Helmet>
            <AppBar position="static" className={classes.appBar}>
              <Tabs
                value={index}
                onChange={(e, v) => {
                  this.setState({
                    index: v,
                  });
                  history.push(`?index=${v}`);
                }}
                indicatorColor="primary"
                textColor="primary"
                scrollable
                scrollButtons="auto"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
              >
                <Tab
                  label="All"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              </Tabs>
            </AppBar>
            <div className="container withAppBarAndTabs">
              {this.getStepContent()}
            </div>
          </div>
        )}
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formUserOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormUser
          open={formUserOpen}
          user={selectedUser}
          close={() => {
            this.setState({
              formUserOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedUser: undefined,
                }),
              200
            );
          }}
          createUser={createUser}
          deleteUser={deleteUser}
          updateUser={updateUser}
          groups={groups}
          getUserGroups={getUserGroups}
          createUserGroup={createUserGroup}
          deleteUserGroup={deleteUserGroup}
          refresh={refresh}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperUsersPage);

export default (theme) => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  warningButton: {
    color: "#ff9800 !important",
    background: "white",
    "&:hover": {
      background: "#ff9800",
      color: "white !important",
      border: "1px solid white",
    },
  },
  errorButton: {
    color: "#f44336 !important",
    background: "white",
    "&:hover": {
      background: "#f44336",
      color: "white !important",
      border: "1px solid white",
    },
  },
  successButton: {
    color: "#4caf50 !important",
    background: "white",
    "&:hover": {
      background: "#4caf50",
      color: "white !important",
      border: "1px solid white",
    },
  },
  infoButton: {
    color: "#2196f3 !important",
    background: "white",
    "&:hover": {
      background: "#2196f3",
      color: "white !important",
      border: "1px solid white",
    },
  },
  defaultButton: {
    color: "#424242 !important",
    background: "white",
    "&:hover": {
      background: "#424242",
      color: "white !important",
      border: "1px solid white",
    },
  },
  leftMargin: {
    marginLeft: 10,
  },
});

import { GET_MONTHLY_SUPPORT_TIMELOGS } from "constants/monthlySupports";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_MONTHLY_SUPPORT_TIMELOGS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};

import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "support";
const timelogs = `/${service}/timelogs`;

export function getTimelogs() {
  const url = `${timelogs}`;
  return {
    method: GET,
    url,
  };
}

export function getTimelogById(id) {
  const url = `${timelogs}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTimelog(id, body) {
  const url = `${timelogs}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTimelog(id) {
  const url = `${timelogs}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTimelog(body) {
  const url = `${timelogs}`;
  return {
    method: POST,
    url,
    body,
  };
}

import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "support";
const supportItemTypes = `${REACT_APP_API_PREFIX}/${service}/supportItemTypes`;

export function getSupportItemTypes() {
  const url = `${supportItemTypes}`;
  return {
    method: GET,
    url,
  };
}

export function getSupportItemTypeById(id) {
  const url = `${supportItemTypes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSupportItemType(id, body) {
  const url = `${supportItemTypes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSupportItemType(id) {
  const url = `${supportItemTypes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSupportItemType(body) {
  const url = `${supportItemTypes}`;
  return {
    method: POST,
    url,
    body,
  };
}

import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Chip from "@material-ui/core/Chip";

// constants
import {
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_8_CHAR,
} from "constants/regexp";

// styles
import styles from "./styles";

class ResetPassword extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    resetPassword: PropTypes.func,
    storeResetPasswordToken: PropTypes.func,
    token: PropTypes.string,
    fullScreen: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      password: "",
      passwordConfirmation: "",
      eightChar: false,
      numerical: false,
      alphabetical: false,
      match: false,
      focused: false,
      showPassword: false,
      showpasswordConfirmation: false,
      validationPending: false,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    const { passwordConfirmation, password } = this.state;

    if (name === "password") {
      let eightChar = false;
      let numerical = false;
      let alphabetical = false;
      let match = false;

      if (AT_LEAST_8_CHAR.test(value)) {
        eightChar = true;
      }

      if (AT_LEAST_1_NUMERICAL_CHAR.test(value)) {
        numerical = true;
      }

      if (AT_LEAST_1_ALPHABETICAL_CHAR.test(value)) {
        alphabetical = true;
      }

      if (value === passwordConfirmation) {
        match = true;
      }

      this.setState({
        eightChar,
        numerical,
        alphabetical,
        match,
      });
    }

    if (name === "passwordConfirmation") {
      let match = false;
      if (value === password) {
        match = true;
      }

      this.setState({ match });
    }

    this.setState({
      [name]: value,
    });
  };

  init() {
    this.setState({
      loading: false,
      password: "",
      passwordConfirmation: "",
      eightChar: false,
      numerical: false,
      alphabetical: false,
      match: false,
      focused: false,
      showPassword: false,
      showpasswordConfirmation: false,
      validationPending: false,
    });
  }

  isValid() {
    const { eightChar, numerical, alphabetical, match } = this.state;

    return match && alphabetical && numerical && eightChar;
  }

  async resetPassword() {
    const { resetPassword, token, storeResetPasswordToken } = this.props;
    const { NotificationCenter } = this.context;
    const { password, passwordConfirmation } = this.state;

    const body = {
      passwordConfirmation,
      password,
    };

    this.setState({ loading: true });
    const resp = await resetPassword(body, token);
    if (resp.success) {
      this.setState({ loading: false });
      storeResetPasswordToken();
      NotificationCenter.sweetAlert(
        {
          title: "Good Job !",
          subtitle: "You password has been reset.",
          body: "You can now login using your new password",
          success: true,
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: "Got it",
            level: "success",
          },
        }
      );
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      children,
      fullScreen,
      token,
      classes,
      storeResetPasswordToken,
    } = this.props;

    const {
      password,
      passwordConfirmation,
      eightChar,
      numerical,
      alphabetical,
      match,
      focused,
      showPassword,
      showpasswordConfirmation,
      loading,
      validationPending,
    } = this.state;

    return (
      <div>
        {children}
        <Dialog
          fullScreen={fullScreen}
          open={token !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!loading && !validationPending && (
            <div>
              <div className={classes.userImage}>
                <img
                  alt=""
                  src={
                    "https://storage.googleapis.com/wid-arranger/images/keys.png"
                  }
                />
              </div>
              <DialogTitle id="alert-dialog-title">
                Reset your Password
              </DialogTitle>
              <DialogContent>
                <div className={classes.formContent}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.textField}>
                        <InputLabel htmlFor="adornment-password">
                          Password
                        </InputLabel>
                        <Input
                          id="adornment-password"
                          onFocus={() => this.setState({ focused: true })}
                          label="Password *"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={this.handleChange("password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex="-1"
                                aria-label="Toggle password visibility"
                                onClick={() =>
                                  this.setState({ showPassword: !showPassword })
                                }
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.textField}>
                        <InputLabel htmlFor="adornment-password-retype">
                          Password Retype
                        </InputLabel>
                        <Input
                          onFocus={() => this.setState({ focused: true })}
                          type={showpasswordConfirmation ? "text" : "password"}
                          id="adornment-password-retype"
                          label="Retype Password *"
                          value={passwordConfirmation}
                          onChange={this.handleChange("passwordConfirmation")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex="-1"
                                aria-label="Toggle password visibility"
                                onClick={() =>
                                  this.setState({
                                    showpasswordConfirmation: !showpasswordConfirmation,
                                  })
                                }
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showpasswordConfirmation ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    {focused && (
                      <Grid container spacing={1}>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: eightChar ? "#4caf50" : "#ff9800",
                            }}
                            label={"At least 8 characters"}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: numerical ? "#4caf50" : "#ff9800",
                            }}
                            label={"At least 1 digit"}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: alphabetical ? "#4caf50" : "#ff9800",
                            }}
                            label={"At least 1 letter"}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: match ? "#4caf50" : "#ff9800",
                            }}
                            label={"Passwords match"}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => storeResetPasswordToken()}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!this.isValid()}
                  variant="contained"
                  onClick={this.resetPassword.bind(this)}
                >
                  Submit
                </Button>
              </DialogActions>
            </div>
          )}
          {loading && (
            <div className={classes.progressContainer}>
              <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
              <CircularProgress />
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(ResetPassword));

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Public from "@material-ui/icons/Public";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// config
import {
  DEFAULT_ORGANIZATION_TOKEN,
  SIGN_UP_ENABLED,
  SIGN_UP_ORGANIZATION_TOKEN_ENABLED,
} from "config";
// constants
import {
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_8_CHAR,
  EMAIL,
} from "constants/regexp";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class Signup extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    history: PropTypes.object,
    fullScreen: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  static childContextTypes = {
    openSignup: PropTypes.func,
    closeSignup: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      signupOpen: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      token: "",
      organizationToken: DEFAULT_ORGANIZATION_TOKEN || "",
      passwordConfirmation: "",
      eightChar: false,
      numerical: false,
      alphabetical: false,
      validEmail: false,
      emailBlur: false,
      match: false,
      focused: false,
      showPassword: false,
      showPasswordConfirmation: false,
      validationPending: false,
    };
  }

  getChildContext() {
    return {
      openSignup: this.openSignup.bind(this),
      closeSignup: this.closeSignup.bind(this),
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    const { passwordConfirmation, password } = this.state;

    if (name === "email") {
      let validEmail = false;
      if (EMAIL.test(value)) {
        validEmail = true;
      }

      this.setState({ validEmail });
    }

    if (name === "password") {
      let eightChar = false;
      let numerical = false;
      let alphabetical = false;
      let match = false;

      if (AT_LEAST_8_CHAR.test(value)) {
        eightChar = true;
      }

      if (AT_LEAST_1_NUMERICAL_CHAR.test(value)) {
        numerical = true;
      }

      if (AT_LEAST_1_ALPHABETICAL_CHAR.test(value)) {
        alphabetical = true;
      }

      if (value === passwordConfirmation) {
        match = true;
      }

      this.setState({
        eightChar,
        numerical,
        alphabetical,
        match,
      });
    }

    if (name === "passwordConfirmation") {
      let match = false;
      if (value === password) {
        match = true;
      }

      this.setState({ match });
    }

    this.setState({
      [name]: value,
    });
  };

  init() {
    this.setState({
      loading: false,
      signupOpen: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      token: "",
      organizationToken: "",
      passwordConfirmation: "",
      eightChar: false,
      numerical: false,
      alphabetical: false,
      validEmail: false,
      emailBlur: false,
      match: false,
      focused: false,
      showPassword: false,
      showPasswordConfirmation: false,
      validationPending: false,
    });
  }

  openSignup(inviteToken) {
    this.setState({ signupOpen: true, inviteToken });
  }

  closeSignup() {
    this.init();
  }

  isValid() {
    const {
      firstName,
      lastName,
      email,
      eightChar,
      numerical,
      alphabetical,
      match,
      validEmail,
    } = this.state;

    return (
      match &&
      alphabetical &&
      numerical &&
      eightChar &&
      email.length &&
      firstName.length &&
      lastName.length &&
      validEmail
    );
  }

  async signup() {
    const { signup } = this.props;
    const {
      email,
      password,
      firstName,
      lastName,
      passwordConfirmation,
      organizationToken,
      inviteToken,
    } = this.state;

    const body = {
      email,
      password,
      passwordConfirmation,
      firstName,
      lastName,
      inviteToken,
      organizationToken: organizationToken.length
        ? organizationToken
        : undefined,
    };

    this.setState({ loading: true });
    const resp = await signup(body);
    if (resp.success) {
      this.setState({ loading: false, validationPending: true });
    } else {
      this.setState({ loading: false });
    }
  }

  async validateEmail() {
    const { NotificationCenter } = this.context;
    const { validateEmail } = this.props;
    const { token, email } = this.state;

    const resp = await validateEmail(token, { email });
    if (resp.success) {
      this.closeSignup();
      this.init();
      NotificationCenter.sweetAlert(
        {
          title: "Good Job !",
          subtitle: "Your email has been successfully confirmed.",
          body: "You can now start using the portal",
          success: true,
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: "Sign in",
            level: "success",
            callback: () => {
              debugger;
            },
          },
        }
      );
    }
  }

  render() {
    const { children, classes, fullScreen } = this.props;
    const {
      signupOpen,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      eightChar,
      numerical,
      alphabetical,
      match,
      focused,
      showPassword,
      showPasswordConfirmation,
      validEmail,
      emailBlur,
      loading,
      validationPending,
      token,
      organizationToken,
      inviteToken,
    } = this.state;

    if (SIGN_UP_ENABLED !== true) {
      return children;
    }

    return (
      <div>
        {children}
        <Dialog
          fullScreen={fullScreen}
          open={signupOpen}
          onClose={this.closeSignup.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!loading && !validationPending && (
            <div>
              <div className={classes.userImage}>
                <img
                  alt=""
                  src={
                    "https://storage.googleapis.com/wid-arranger/images/add-user.png"
                  }
                />
              </div>
              <DialogTitle id="alert-dialog-title">
                Create an account
              </DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1">
                  To sign up, please fill up the form below. Fields marked with
                  * are required.
                </Typography>
                <br />
                <div className={classes.formContent}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="firstName"
                        label="First Name *"
                        className={classes.textField}
                        value={firstName}
                        onChange={this.handleChange("firstName")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="lastName"
                        label="Last Name *"
                        className={classes.textField}
                        value={lastName}
                        onChange={this.handleChange("lastName")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!validEmail && emailBlur}
                        onBlur={() => this.setState({ emailBlur: true })}
                        id="email"
                        label="Email *"
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange("email")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.textField}>
                        <InputLabel htmlFor="adornment-password">
                          Password
                        </InputLabel>
                        <Input
                          id="adornment-password"
                          onFocus={() => this.setState({ focused: true })}
                          label="Password *"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={this.handleChange("password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex="-1"
                                aria-label="Toggle password visibility"
                                onClick={() =>
                                  this.setState({ showPassword: !showPassword })
                                }
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.textField}>
                        <InputLabel htmlFor="adornment-password-retype">
                          Password Confirmation
                        </InputLabel>
                        <Input
                          onFocus={() => this.setState({ focused: true })}
                          type={showPasswordConfirmation ? "text" : "password"}
                          id="adornment-password-retype"
                          label="Retype Password *"
                          value={passwordConfirmation}
                          onChange={this.handleChange("passwordConfirmation")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex="-1"
                                aria-label="Toggle password visibility"
                                onClick={() =>
                                  this.setState({
                                    showPasswordConfirmation: !showPasswordConfirmation,
                                  })
                                }
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPasswordConfirmation ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    {focused && (
                      <Grid container spacing={1}>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: eightChar ? "#4caf50" : "#ff9800",
                            }}
                            label={"At least 8 characters"}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: numerical ? "#4caf50" : "#ff9800",
                            }}
                            label={"At least 1 digit"}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: alphabetical ? "#4caf50" : "#ff9800",
                            }}
                            label={"At least 1 letter"}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            style={{
                              color: "white",
                              background: match ? "#4caf50" : "#ff9800",
                            }}
                            label={"Passwords match"}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {SIGN_UP_ORGANIZATION_TOKEN_ENABLED && !inviteToken ? (
                      <Grid item xs={6}>
                        <FormControl className={classes.textField}>
                          <InputLabel htmlFor="organizationToken">
                            Organization Token
                          </InputLabel>
                          <Input
                            id="organizationToken"
                            label="Organization Token"
                            value={organizationToken}
                            onChange={this.handleChange("organizationToken")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton>
                                  <Public />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      []
                    )}
                    {SIGN_UP_ORGANIZATION_TOKEN_ENABLED && !inviteToken ? (
                      <Grid item xs={6}>
                        <Typography variant="caption" color="textSecondary">
                          Optionnal : Join directly an existing organization by
                          providing the secret token.
                        </Typography>
                      </Grid>
                    ) : (
                      []
                    )}
                    {inviteToken ? (
                      <Grid item xs={12}>
                        <div
                          style={{
                            background: "rgba(155, 155, 155, 0.1)",
                            border: "solid 1px rgba(155, 155, 155, 0.1)",
                            borderRadius: 4,
                            padding: 4,
                          }}
                        >
                          <Typography variant="caption" color="textSecondary">
                            Organization Invitation Token
                          </Typography>
                          <Typography>{inviteToken}</Typography>
                        </div>
                      </Grid>
                    ) : (
                      []
                    )}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeSignup.bind(this)} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!this.isValid()}
                  variant="contained"
                  onClick={this.signup.bind(this)}
                >
                  Submit
                </Button>
              </DialogActions>
            </div>
          )}
          {loading && (
            <div className={classes.progressContainer}>
              <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
              <CircularProgress />
            </div>
          )}
          {validationPending && (
            <div>
              <div className={classes.userImage}>
                <img
                  alt=""
                  src={
                    "https://storage.googleapis.com/wid-arranger/images/envelope.png"
                  }
                />
              </div>
              <DialogTitle id="alert-dialog-title">
                Please confirm your email
              </DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1">
                  Copy the code you just received by email below :
                </Typography>
                <br />
                <div className={classes.formContent}>
                  <TextField
                    id="token"
                    label="Verification Code"
                    className={classes.textField}
                    value={token}
                    onChange={this.handleChange("token")}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeSignup.bind(this)} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!token.length}
                  variant="contained"
                  onClick={this.validateEmail.bind(this)}
                >
                  Confirm Email
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(Signup));

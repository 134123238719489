import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperSupportItemTypePage from "../component/WrapperSupportItemTypePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  supportItemTypes: state.supportItemTypes,
});

const mapDispatchToProps = (dispatch) => ({
  getSupportItemTypes: () => dispatch(Actions.getSupportItemTypes()),
  getSupportItemTypeById: (...args) =>
    dispatch(Actions.getSupportItemTypeById(...args)),
  createSupportItemType: (...args) =>
    dispatch(Actions.createSupportItemType(...args)),
  deleteSupportItemType: (...args) =>
    dispatch(Actions.deleteSupportItemType(...args)),
  updateSupportItemType: (...args) =>
    dispatch(Actions.updateSupportItemType(...args)),
});
class SupportItemTypePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    supportItemTypes: PropTypes.array,
    getSupportItemTypes: PropTypes.func,
    getSupportItemTypeById: PropTypes.func,
    createSupportItemType: PropTypes.func,
    deleteSupportItemType: PropTypes.func,
    updateSupportItemType: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, supportItemTypes } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: supportItemTypes.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getSupportItemTypes } = this.props;
    await Promise.all([getSupportItemTypes()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      supportItemTypes,
      getSupportItemTypeById,
      createSupportItemType,
      deleteSupportItemType,
      updateSupportItemType,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSupportItemTypePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        supportItemTypes={supportItemTypes}
        getSupportItemTypeById={getSupportItemTypeById}
        createSupportItemType={createSupportItemType}
        deleteSupportItemType={deleteSupportItemType}
        updateSupportItemType={updateSupportItemType}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportItemTypePage);

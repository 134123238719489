import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "arranger";
const gitRepos = `${REACT_APP_API_PREFIX}/${service}/gitRepos`;

export function getGitRepos(filters) {
  const url = `${gitRepos}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getGitRepoById(id) {
  const url = `${gitRepos}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function protectGitRepo(id) {
  const url = `${gitRepos}/${id}/protect`;
  return {
    method: GET,
    url,
  };
}

export function starGitRepo(id) {
  const url = `${gitRepos}/${id}/star`;
  return {
    method: GET,
    url,
  };
}

export function forceUpdateGitRepo(id) {
  const url = `${gitRepos}/${id}/forceUpdate`;
  return {
    method: GET,
    url,
  };
}

export function updateGitRepo(id, body) {
  const url = `${gitRepos}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGitRepo(id) {
  const url = `${gitRepos}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGitRepo(body) {
  const url = `${gitRepos}`;
  return {
    method: POST,
    url,
    body,
  };
}

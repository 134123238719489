import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCustomerPage from "../component/WrapperCustomerPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  customers: state.customers,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(Actions.getCustomers()),
  getCustomerById: (...args) => dispatch(Actions.getCustomerById(...args)),
  createCustomer: (...args) => dispatch(Actions.createCustomer(...args)),
  deleteCustomer: (...args) => dispatch(Actions.deleteCustomer(...args)),
  updateCustomer: (...args) => dispatch(Actions.updateCustomer(...args)),
  getCustomerUsers: (...args) => dispatch(Actions.getCustomerUsers(...args)),
  addCustomerUser: (...args) => dispatch(Actions.addCustomerUser(...args)),
  deleteCustomerUser: (...args) =>
    dispatch(Actions.deleteCustomerUser(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
});
class CustomerPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    customers: PropTypes.array,
    getCustomers: PropTypes.func,
    getCustomerById: PropTypes.func,
    createCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    getCustomerUsers: PropTypes.func,
    addCustomerUser: PropTypes.func,
    deleteCustomerUser: PropTypes.func,
    users: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, customers } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: customers.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getCustomers, getUsers } = this.props;
    await Promise.all([getCustomers(), getUsers()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      customers,
      getCustomerById,
      createCustomer,
      deleteCustomer,
      updateCustomer,
      getCustomerUsers,
      addCustomerUser,
      deleteCustomerUser,
      users,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCustomerPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        customers={customers}
        history={history}
        getCustomerById={getCustomerById}
        createCustomer={createCustomer}
        deleteCustomer={deleteCustomer}
        updateCustomer={updateCustomer}
        urlParams={urlParams}
        getCustomerUsers={getCustomerUsers}
        addCustomerUser={addCustomerUser}
        deleteCustomerUser={deleteCustomerUser}
        users={users}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);

import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSupportItemType from "components/FormSupportItemType";
// components
import SupportItemTypesList from "components/SupportItemTypesList";
import PropTypes from "prop-types";
import React from "react";
import Page from "../../../components/Page";
// styles
import styles from "./styles";

class WrapperSupportItemTypePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    supportItemTypes: PropTypes.array,
    createSupportItemType: PropTypes.func,
    deleteSupportItemType: PropTypes.func,
    updateSupportItemType: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSupportItemType: undefined,
      formSupportItemTypeOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { supportItemTypes } = this.props;

    switch (index) {
      case 0:
        return (
          <SupportItemTypesList
            supportItemTypes={supportItemTypes}
            onSelect={(selectedSupportItemType) =>
              this.setState({
                selectedSupportItemType,
                formSupportItemTypeOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSupportItemType,
      deleteSupportItemType,
      updateSupportItemType,
      refresh,
    } = this.props;

    const {
      index,
      selectedSupportItemType,
      formSupportItemTypeOpen,
    } = this.state;

    return (
      <Page
        helmet="Settings"
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Support Item Types"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formSupportItemTypeOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
      >
        {this.getStepContent()}
        <FormSupportItemType
          open={formSupportItemTypeOpen}
          supportItemType={selectedSupportItemType}
          close={() => {
            this.setState({
              formSupportItemTypeOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSupportItemType: undefined,
                }),
              200
            );
          }}
          createSupportItemType={createSupportItemType}
          deleteSupportItemType={deleteSupportItemType}
          updateSupportItemType={updateSupportItemType}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSupportItemTypePage);

import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";

// styles
const styles = (theme) => ({
  root: {
    textAlign: "center",
    cursor: "pointer",
  },
  container: {
    padding: 5,
    cursor: "pointer",
  },
  tag: {
    background: theme.palette.background.paper,
    marginTop: 4,
    borderRadius: 12,
  },
});

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    color: PropTypes.string,
    withName: PropTypes.bool,
    fullName: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string,
  };

  render() {
    const { withName, color, name, size, classes, fullName, url } = this.props;

    return (
      <div
        onClick={
          url ? () => window.open(`https://${url}`, "_blank") : undefined
        }
      >
        <Zoom in>
          <div className={classes.root}>
            <Paper
              style={{
                paddingLeft: fullName ? 4 : undefined,
                paddingRight: fullName ? 4 : undefined,
                minWidth: fullName ? size : undefined,
                width: fullName ? undefined : size,
                margin: "auto",
                height: size,
                border: `solid ${size * 0.05}px ${color}`,
                background: "#424242",
                color,
                fontFamily: "Futura",
                fontSize: size * 0.5,
                borderTopRightRadius: size * 0.5,
                borderBottomLeftRadius: size * 0.5,
              }}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: size }}
              >
                <Grid item>{fullName ? name : `${name[0]}${name[1]}`}</Grid>
              </Grid>
            </Paper>
            {withName && <Typography variant="body2">{name}</Typography>}
          </div>
        </Zoom>
      </div>
    );
  }
}

export default withStyles(styles)(App);

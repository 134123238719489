import { networkAction } from "helpers/network/networkAction";

import {
  GET_TIMELOGS,
  GET_TIMELOG_BY_ID,
  DELETE_TIMELOG,
  UPDATE_TIMELOG,
  CREATE_TIMELOG,
} from "constants/timelogs";

import * as Api from "api";

export const getTimelogs = () => async (dispatch) =>
  networkAction(dispatch, GET_TIMELOGS, Api.getTimelogs, []);

export const getTimelogById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TIMELOG_BY_ID, Api.getTimelogById, [id]);

export const deleteTimelog = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TIMELOG, Api.deleteTimelog, [id]);

export const createTimelog = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TIMELOG, Api.createTimelog, [body]);

export const updateTimelog = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TIMELOG, Api.updateTimelog, [id, body]);

import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "support";
const monthlySupports = `${REACT_APP_API_PREFIX}/${service}/monthlySupports`;

export function getMonthlySupports(filters) {
  const url = `${monthlySupports}${filters || ""}`;
  return {
    method: GET,
    url,
  };
}

export function getMonthlySupportTimelogs(monthlySupportID) {
  const url = `${monthlySupports}/${monthlySupportID}/timelogs`;
  return {
    method: GET,
    url,
  };
}

export function createMonthlySupportInvoice(monthlySupportID) {
  const url = `${monthlySupports}/${monthlySupportID}/invoice`;
  return {
    method: GET,
    url,
  };
}

export function getMonthlySupportById(id) {
  const url = `${monthlySupports}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function previewMonthlySupport(id) {
  const url = `${monthlySupports}/${id}/preview`;
  return {
    method: GET,
    url,
  };
}

export function updateMonthlySupport(id, body) {
  const url = `${monthlySupports}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMonthlySupport(id) {
  const url = `${monthlySupports}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMonthlySupport(body) {
  const url = `${monthlySupports}`;
  return {
    method: POST,
    url,
    body,
  };
}

// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCustomerPage from "../component/WrapperCustomerPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getCustomerById: (...args) => dispatch(Actions.getCustomerById(...args)),
  getCustomerUsers: (...args) => dispatch(Actions.getCustomerUsers(...args)),
  addCustomerUser: (...args) => dispatch(Actions.addCustomerUser(...args)),
  deleteCustomerUser: (...args) =>
    dispatch(Actions.deleteCustomerUser(...args)),

  getSupportItemTypes: (...args) =>
    dispatch(Actions.getSupportItemTypes(...args)),

  getSupports: (...args) => dispatch(Actions.getSupports(...args)),
  getSupportById: (...args) => dispatch(Actions.getSupportById(...args)),
  deleteSupport: (...args) => dispatch(Actions.deleteSupport(...args)),
  createSupport: (...args) => dispatch(Actions.createSupport(...args)),
  updateSupport: (...args) => dispatch(Actions.updateSupport(...args)),

  getMonthlySupports: (...args) =>
    dispatch(Actions.getMonthlySupports(...args)),
  getMonthlySupportTimelogs: (...args) =>
    dispatch(Actions.getMonthlySupportTimelogs(...args)),
  getMonthlySupportById: (...args) =>
    dispatch(Actions.getMonthlySupportById(...args)),
  deleteMonthlySupport: (...args) =>
    dispatch(Actions.deleteMonthlySupport(...args)),
  createMonthlySupport: (...args) =>
    dispatch(Actions.createMonthlySupport(...args)),
  updateMonthlySupport: (...args) =>
    dispatch(Actions.updateMonthlySupport(...args)),

  getSupportItems: (...args) => dispatch(Actions.getSupportItems(...args)),
  getSupportItemById: (...args) =>
    dispatch(Actions.getSupportItemById(...args)),
  deleteSupportItem: (...args) => dispatch(Actions.deleteSupportItem(...args)),
  createSupportItem: (...args) => dispatch(Actions.createSupportItem(...args)),
  updateSupportItem: (...args) => dispatch(Actions.updateSupportItem(...args)),

  getTimelogs: (...args) => dispatch(Actions.getTimelogs(...args)),
  deleteTimelog: (...args) => dispatch(Actions.deleteTimelog(...args)),
  createTimelog: (...args) => dispatch(Actions.createTimelog(...args)),
  updateTimelog: (...args) => dispatch(Actions.updateTimelog(...args)),
  createMonthlySupportInvoice: (...args) =>
    dispatch(Actions.createMonthlySupportInvoice(...args)),
});
class CustomerPage extends Component {
  static propTypes = {
    app: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.array,
    getCustomerById: PropTypes.object,
    getCustomerUsers: PropTypes.object,
    addCustomerUser: PropTypes.object,
    deleteCustomerUser: PropTypes.object,

    getSupportItemTypes: PropTypes.func,
    supportItemTypes: PropTypes.array,

    getSupports: PropTypes.func,
    getSupportById: PropTypes.func,
    deleteSupport: PropTypes.func,
    createSupport: PropTypes.func,
    updateSupport: PropTypes.func,

    getMonthlySupports: PropTypes.func,
    getMonthlySupportById: PropTypes.func,
    getMonthlySupportTimelogs: PropTypes.func,
    deleteMonthlySupport: PropTypes.func,
    createMonthlySupport: PropTypes.func,
    updateMonthlySupport: PropTypes.func,

    getSupportItems: PropTypes.func,
    getSupportItemById: PropTypes.func,
    deleteSupportItem: PropTypes.func,
    createSupportItem: PropTypes.func,
    updateSupportItem: PropTypes.func,

    timelogs: PropTypes.array,
    getTimelogs: PropTypes.func,
    deleteTimelog: PropTypes.func,
    createTimelog: PropTypes.func,
    updateTimelog: PropTypes.func,
    createMonthlySupportInvoice: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    const urlParams = getJsonFromUrl(location);

    this.state = {
      urlParams,
      customerID: Number(urlParams.customerID),
      customer: undefined,
      loading: true,
    };

    this.refresh();
  }

  async refresh() {
    const { getCustomerById, getUsers, getSupportItemTypes } = this.props;
    const { customerID } = this.state;

    const resps = await Promise.all([
      getCustomerById(customerID),
      getUsers(),
      getSupportItemTypes(),
    ]);

    this.setState({
      customer: resps[0].payload,
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      getCustomerUsers,
      addCustomerUser,
      deleteCustomerUser,
      users,
      getSupports,
      getSupportById,
      deleteSupport,
      createSupport,
      updateSupport,
      getMonthlySupports,
      getMonthlySupportTimelogs,
      getMonthlySupportById,
      deleteMonthlySupport,
      createMonthlySupport,
      updateMonthlySupport,
      getSupportItems,
      getSupportItemById,
      deleteSupportItem,
      createSupportItem,
      updateSupportItem,
      app,
      supportItemTypes,
      getTimelogs,
      deleteTimelog,
      createTimelog,
      updateTimelog,
      user,
      timelogs,
      createMonthlySupportInvoice,
    } = this.props;

    const { urlParams, customer, loading } = this.state;

    const utils = {
      getCustomerUsers,
      addCustomerUser,
      deleteCustomerUser,
      getSupports,
      getSupportById,
      deleteSupport,
      createSupport,
      updateSupport,
      getMonthlySupports,
      getMonthlySupportById,
      getMonthlySupportTimelogs,
      deleteMonthlySupport,
      createMonthlySupport,
      updateMonthlySupport,
      getSupportItems,
      getSupportItemById,
      deleteSupportItem,
      createSupportItem,
      updateSupportItem,
      supportItemTypes,
      getTimelogs,
      deleteTimelog,
      createTimelog,
      updateTimelog,
      createMonthlySupportInvoice,
    };

    return (
      <WrapperCustomerPage
        loading={loading}
        customer={customer}
        history={history}
        location={location}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        utils={utils}
        users={users}
        user={user}
        timelogs={timelogs}
        supportItemTypes={supportItemTypes}
        app={app}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);

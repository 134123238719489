import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SHIP_NODES,
  GET_SHIP_NODE_BY_ID,
  DELETE_SHIP_NODE,
  UPDATE_SHIP_NODE,
  CREATE_SHIP_NODE,
} from 'constants/shipNodes';

import * as Api from 'api';

export const getShipNodes = () => async dispatch => networkAction(
  dispatch,
  GET_SHIP_NODES,
  Api.getShipNodes,
  []
);

export const getShipNodeById = (id) => async dispatch => networkAction(
  dispatch,
  GET_SHIP_NODE_BY_ID,
  Api.getShipNodeById,
  [id]
);

export const deleteShipNode = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_SHIP_NODE,
  Api.deleteShipNode,
  [id]
);

export const createShipNode = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_SHIP_NODE,
  Api.createShipNode,
  [body]
);

export const updateShipNode = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SHIP_NODE,
  Api.updateShipNode,
  [id, body]
);

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormSupportItem extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    monthlySupport: PropTypes.object,
    supportItemTypes: PropTypes.array,
    close: PropTypes.func,
    open: PropTypes.bool,

    supportItem: PropTypes.object,

    createSupportItem: PropTypes.func,
    updateSupportItem: PropTypes.func,
    deleteSupportItem: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      supportItemTypeID: "",
      unit: "",
      extraRate: "",
      rate: "",
      editable: false,
      recommendedValue: "",
      minValue: "",
      currentValue: "",
      maxValue: "",
      setValue: "",
      setDate: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.supportItem);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(supportItem) {
    this.setState({
      loading: false,
      unit: supportItem ? supportItem.unit : "",
      supportItemTypeID: supportItem ? supportItem.supportTypeID : "",
      extraRate: supportItem ? supportItem.extraRate / 100 : "",
      rate: supportItem ? supportItem.rate / 100 : "",
      editable: supportItem ? supportItem.editable : false,
      recommendedValue: supportItem ? supportItem.recommendedValue : "",
      minValue: supportItem ? supportItem.minValue : "",
      currentValue: supportItem ? supportItem.currentValue / 100 : "",
      maxValue: supportItem ? supportItem.maxValue : "",
      setValue: supportItem ? supportItem.setValue : "",
      setDate: supportItem ? supportItem.setDate : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this support Item, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteSupportItem, supportItem, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteSupportItem(supportItem.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Support Item has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      this.init();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateSupportItem,
      createSupportItem,
      supportItem,
      monthlySupport,
      close,
      refresh,
    } = this.props;

    const {
      unit,
      extraRate,
      rate,
      editable,
      recommendedValue,
      minValue,
      currentValue,
      maxValue,
      setValue,
      setDate,
      supportItemTypeID,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (supportItem) {
      resp = await updateSupportItem(supportItem.id, {
        supportTypeID: supportItemTypeID,
        unit,
        extraRate: Number(extraRate * 100),
        rate: Number(rate * 100),
        editable,
        recommendedValue: Number(recommendedValue),
        minValue: Number(minValue),
        currentValue: Number(currentValue * 100),
        maxValue: Number(maxValue),
        setValue: Number(setValue),
        setDate,
      });
    } else {
      resp = await createSupportItem({
        monthlySupportID: monthlySupport.id,
        supportTypeID: supportItemTypeID,
        unit,
        extraRate: Number(extraRate * 100),
        rate: Number(rate * 100),
        editable,
        recommendedValue: Number(recommendedValue),
        minValue: Number(minValue),
        currentValue: Number(currentValue * 100),
        maxValue: Number(maxValue),
        setValue: Number(setValue),
        setDate,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: supportItem
          ? "Support Item has been updated."
          : "Support Item has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      supportItem,
      close,
      supportItemTypes,
    } = this.props;

    const {
      unit,
      extraRate,
      rate,
      editable,
      recommendedValue,
      minValue,
      currentValue,
      maxValue,
      setValue,
      setDate,
      loading,
      supportItemTypeID,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {supportItem
                ? `${supportItem.name}`
                : "Let's Create a New Support Item"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {supportItem
                  ? "To update the support Item, fill the form bellow :"
                  : "To create a new support Item, fill the form bellow :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="serviceName-simple">Type</InputLabel>
                    <Select
                      value={supportItemTypeID}
                      onChange={this.handleChange("supportItemTypeID")}
                      inputProps={{
                        name: "supportItemTypeID",
                        id: "supportItemTypeID-simple",
                      }}
                    >
                      {supportItemTypes.map((st) => (
                        <MenuItem key={st.id} value={st.id}>
                          <span
                            style={{
                              background: st.color,
                              padding: 10,
                              borderRadius: 30,
                              height: 20,
                              width: 20,
                              textAlign: "center",
                            }}
                          >
                            <img src={st.icon} alt="type" height="15" />
                          </span>
                          <span style={{ marginLeft: 20 }}>{st.name}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="outlined-adornment-rate"
                    className={classes.textField}
                    label="Rate"
                    value={rate}
                    onChange={this.handleChange("rate")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="outlined-adornment-extraRate"
                    className={classes.textField}
                    label="Extra Rate"
                    value={extraRate}
                    onChange={this.handleChange("extraRate")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="unit"
                    label="Unit"
                    className={classes.textField}
                    value={unit}
                    onChange={this.handleChange("unit")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editable}
                        onChange={this.handleCheckboxChange("editable")}
                        value="dflt"
                        color="primary"
                      />
                    }
                    label="Editable"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="minValue"
                    label="Min Value"
                    className={classes.textField}
                    value={minValue}
                    onChange={this.handleChange("minValue")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="maxValue"
                    label="Max Value"
                    className={classes.textField}
                    value={maxValue}
                    onChange={this.handleChange("maxValue")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="recommendedValue"
                    label="Recommended Value"
                    className={classes.textField}
                    value={recommendedValue}
                    onChange={this.handleChange("recommendedValue")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="currentValue"
                    label="Current Value"
                    className={classes.textField}
                    value={currentValue}
                    onChange={this.handleChange("currentValue")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="setValue"
                    label="Set Value"
                    className={classes.textField}
                    value={setValue}
                    onChange={this.handleChange("setValue")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="setDate"
                    type="date"
                    label="Set Date"
                    className={classes.textField}
                    value={setDate}
                    onChange={this.handleChange("setDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {supportItem && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {supportItem ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormSupportItem));

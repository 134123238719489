import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// // actions
// import * as Actions from 'actions';

// component
import WrapperSupportPage from "../component/WrapperSupportPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class SupportPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  render() {
    const { location, history } = this.props;

    const { urlParams } = this.state;

    return (
      <WrapperSupportPage
        history={history}
        location={location}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);

import { networkAction } from "helpers/network/networkAction";

import {
  GET_SUPPORTS,
  GET_SUPPORT_BY_ID,
  DELETE_SUPPORT,
  UPDATE_SUPPORT,
  CREATE_SUPPORT,
} from "constants/supports";

import * as Api from "api";

export const getSupports = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_SUPPORTS, Api.getSupports, [filters]);

export const getSupportById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SUPPORT_BY_ID, Api.getSupportById, [id]);

export const deleteSupport = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUPPORT, Api.deleteSupport, [id]);

export const createSupport = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUPPORT, Api.createSupport, [body]);

export const updateSupport = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUPPORT, Api.updateSupport, [id, body]);

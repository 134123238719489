import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// actions
import * as Actions from "actions";

// component
import WrapperDemoPage from "../component/WrapperDemoPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (...args) => dispatch(Actions.createUser(...args)),
  deleteUser: (...args) => dispatch(Actions.deleteUser(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),
});
class DemoPage extends React.Component {
  static propTypes = {
    createUser: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
  };

  render() {
    const { createUser, deleteUser, updateUser } = this.props;

    return (
      <WrapperDemoPage
        createUser={createUser}
        deleteUser={deleteUser}
        updateUser={updateUser}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DemoPage);

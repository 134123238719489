import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "support";
const supportItems = `${REACT_APP_API_PREFIX}/${service}/supportItems`;

export function getSupportItems(filters) {
  const url = `${supportItems}${filters}`;
  return {
    method: GET,
    url,
  };
}

export function getSupportItemById(id) {
  const url = `${supportItems}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSupportItem(id, body) {
  const url = `${supportItems}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSupportItem(id) {
  const url = `${supportItems}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSupportItem(body) {
  const url = `${supportItems}`;
  return {
    method: POST,
    url,
    body,
  };
}

import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "arranger";
const shipServices = `${REACT_APP_API_PREFIX}/${service}/shipServices`;

export function getShipServices(filters) {
  const url = `${shipServices}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getShipServiceById(id) {
  const url = `${shipServices}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateShipService(id, body) {
  const url = `${shipServices}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteShipService(id) {
  const url = `${shipServices}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createShipService(body) {
  const url = `${shipServices}`;
  return {
    method: POST,
    url,
    body,
  };
}

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormCustomer extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    customer: PropTypes.object,

    createCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      taxNumber: "",
      logo: "",
      timelogID: "",
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      active: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.customer);
    }
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(customer) {
    this.setState({
      loading: false,
      name: customer ? customer.name : "",
      taxNumber: customer ? customer.taxNumber : "",
      logo: customer ? customer.logo : "",
      timelogID: customer ? customer.timelogID : "",
      contactName: customer ? customer.contactName : "",
      contactPhone: customer ? customer.contactPhone : "",
      contactEmail: customer ? customer.contactEmail : "",
      active: customer ? customer.active : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this customer, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCustomer, close, refresh, customer } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteCustomer(customer.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Customer has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateCustomer,
      createCustomer,
      customer,
      refresh,
      close,
    } = this.props;

    const {
      name,
      taxNumber,
      logo,
      timelogID,
      contactName,
      contactPhone,
      contactEmail,
      active,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (customer) {
      resp = await updateCustomer(customer.id, {
        name,
        taxNumber,
        logo,
        timelogID,
        contactName,
        contactPhone,
        contactEmail,
        active,
      });
    } else {
      resp = await createCustomer({
        name,
        taxNumber,
        logo,
        timelogID,
        contactName,
        contactPhone,
        contactEmail,
        active,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: customer
          ? "Customer has been updated."
          : "Customer has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, customer, close } = this.props;

    const {
      name,
      taxNumber,
      logo,
      timelogID,
      contactName,
      contactPhone,
      contactEmail,
      active,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <Grid container alignItems="center">
                {customer && (
                  <Grid item>
                    <img src={logo} alt="logo" style={{ height: 55 }} />
                  </Grid>
                )}
                <Grid item>
                  {customer
                    ? `${customer.name}`
                    : "Let's Create a New Customer"}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {customer
                  ? "To update the customer, fill the form bellow :"
                  : "To create a new customer, fill the form bellow :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="taxNumber"
                    label="Tax Number *"
                    className={classes.textField}
                    value={taxNumber}
                    onChange={this.handleChange("taxNumber")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="logo"
                    label="Logo *"
                    className={classes.textField}
                    value={logo}
                    onChange={this.handleChange("logo")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="timelogID"
                    label="Timelog ID *"
                    className={classes.textField}
                    value={timelogID}
                    onChange={this.handleChange("timelogID")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography display="block" variant="h6">
                    Primary Contact
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="contactName"
                    label="Name *"
                    className={classes.textField}
                    value={contactName}
                    onChange={this.handleChange("contactName")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="contactPhone"
                    label="Phone *"
                    className={classes.textField}
                    value={contactPhone}
                    onChange={this.handleChange("contactPhone")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="contactEmail"
                    label="Email *"
                    className={classes.textField}
                    value={contactEmail}
                    onChange={this.handleChange("contactEmail")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={this.handleCheckboxChange("active")}
                      value="dflt"
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {customer && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {customer ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormCustomer));

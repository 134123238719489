export default (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  card: {
    maxWidth: 400,
    textAlign: "center",
    width: "100%",
    paddingTop: 60,
    margin: "auto",
    background: theme.palette.background.paper,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  button: {
    margin: "auto",
  },
  iForgotButton: {
    padding: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  zoomContainer: {
    textAlign: "center",
    position: "relative",
  },
  signupButton: {
    marginTop: 20,
  },
  header: {
    padding: 20,
    margin: "auto",
    marginTop: -100,
    maxWidth: 320,
    width: "calc(100% - 80px)",
    marginLeft: 20,
    position: "absolute",
    background: theme.palette.secondary[800],
  },
});

import { networkAction } from "helpers/network/networkAction";

import {
  GET_SUPPORT_ITEM_TYPES,
  GET_SUPPORT_ITEM_TYPE_BY_ID,
  DELETE_SUPPORT_ITEM_TYPE,
  UPDATE_SUPPORT_ITEM_TYPE,
  CREATE_SUPPORT_ITEM_TYPE,
} from "constants/supportItemTypes";

import * as Api from "api";

export const getSupportItemTypes = () => async (dispatch) =>
  networkAction(dispatch, GET_SUPPORT_ITEM_TYPES, Api.getSupportItemTypes, []);

export const getSupportItemTypeById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SUPPORT_ITEM_TYPE_BY_ID,
    Api.getSupportItemTypeById,
    [id]
  );

export const deleteSupportItemType = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUPPORT_ITEM_TYPE, Api.deleteSupportItemType, [
    id,
  ]);

export const createSupportItemType = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUPPORT_ITEM_TYPE, Api.createSupportItemType, [
    body,
  ]);

export const updateSupportItemType = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUPPORT_ITEM_TYPE, Api.updateSupportItemType, [
    id,
    body,
  ]);

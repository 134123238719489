import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

// styles
import styles from "./styles";

class TableCustomerEdit extends Component {
  static propTypes = {
    datum: PropTypes.object,
    onEdit: PropTypes.func,
  };

  render() {
    const { datum, onEdit } = this.props;

    return (
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onEdit(datum);
        }}
        aria-label="Delete"
      >
        <Edit />
      </IconButton>
    );
  }
}

export default withStyles(styles)(TableCustomerEdit);
